import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const FAQs = () => {
  const [faqs, setFaqs] = useState(null);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/home/1/');
        setFaqs(response.data.faqs);
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    };
    fetchFAQs();
  }, []);

  if (!faqs) {
    return <Typography>Loading FAQs...</Typography>;
  }

  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        Frequently Asked Questions
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', marginBottom: '2rem' }}>
        Find answers to commonly asked questions about Long Term Mentorship
      </Typography>

      <Box>
        {faqs.map((faq, index) => (
          <Accordion key={index} sx={{ mb: 1, backgroundColor: '#580017', color: '#fff', borderRadius: '20px' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" sx={{ color: '#d3d3d3', borderRadius: '20px' }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default FAQs;
