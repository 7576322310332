import React, { useEffect, useState } from 'react';
import { fetchLoggedInMentorProfile, fetchMentorProfile, updateBookingStatus } from '../services/api';
import { 
  Container, Typography, Button, List, ListItem, ListItemText, Paper, Divider, Stack 
} from '@mui/material';
import { CalendarToday, AccessTime } from '@mui/icons-material';

const MentorDashboard = () => {
  const [mentor, setMentor] = useState(null);

  useEffect(() => {
    const getMentorProfile = async () => {
      try {
        const data = await fetchLoggedInMentorProfile();
        setMentor(data);
      } catch (error) {
        console.error('Error fetching mentor profile:', error);
      }
    };

    getMentorProfile();
  }, []);

  const handleBookingStatusChange = async (bookingId, status) => {
    try {
      const data = await updateBookingStatus(bookingId, status);
      console.log(`Booking ${status} Response:`, data);
      const updatedData = await fetchMentorProfile();
      setMentor(updatedData);
    } catch (error) {
      console.error(`Error ${status} booking:`, error);
      if (error.response) {
        console.error(`Error Response Data:`, error.response.data);
      }
    }
  };

  if (!mentor) {
    return <div>Loading...</div>;
  }

  return (
    <Container sx={{ mt: 4, color: '#fb2f60' }}>
      {/* Mentor Information Section */}
      <Paper elevation={3} sx={{ padding: 3, mb: 4, backgroundColor: '#fed7db' }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#c0332b' }}>
          Welcome, {mentor.name}
        </Typography>
        <Typography variant="h6" sx={{ mb: 1 }}>{mentor.description}</Typography>
        <Typography variant="body1">{mentor.bio}</Typography>
      </Paper>

      {/* Availability Section */}
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#c0332b', mb: 2 }}>
        Availability
      </Typography>
      <List>
        {mentor.availabilities && mentor.availabilities.length > 0 ? (
          mentor.availabilities.map((availability) => (
            <ListItem key={availability.id}>
              <Paper elevation={2} sx={{ width: '100%', padding: 2, mb: 2, backgroundColor: '#fb2f60', color: 'white' }}>
                <ListItemText
                  primary={
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <CalendarToday sx={{ fontSize: 16 }} />
                      <Typography variant="body1" fontWeight="bold">
                        {availability.date}
                      </Typography>
                      <AccessTime sx={{ fontSize: 16 }} />
                      <Typography variant="body1">
                        {availability.start_time} - {availability.end_time}
                      </Typography>
                    </Stack>
                  }
                />
              </Paper>
            </ListItem>
          ))
        ) : (
          <Typography>No availabilities found.</Typography>
        )}
      </List>

      {/* Divider between sections */}
      <Divider sx={{ my: 4 }} />

      {/* Bookings Section */}
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#c0332b', mb: 2 }}>
        Bookings
      </Typography>
      <List>
        {mentor.bookings && mentor.bookings.length > 0 ? (
          mentor.bookings.map((booking) => (
            <ListItem key={booking.id}>
              <Paper elevation={3} sx={{ width: '100%', padding: 2, mb: 2, backgroundColor: '#fed7db' }}>
                <ListItemText
                  primary={
                    <Typography variant="body1" fontWeight="bold" color="#580017">
                      {booking.mentee} booked for {booking.availability.date} from {booking.availability.start_time} to {booking.availability.end_time}
                    </Typography>
                  }
                  secondary={`Booking created at: ${new Date(booking.created_at).toLocaleString()}`}
                />
                {booking.status === 'pending' && (
                  <Stack direction="row" spacing={2}>
                    <Button
                      onClick={() => handleBookingStatusChange(booking.id, 'approved')}
                      variant="contained"
                      sx={{
                        backgroundColor: '#c0332b',
                        '&:hover': { backgroundColor: '#580017' },
                        color: 'white',
                      }}
                    >
                      Approve
                    </Button>
                    <Button
                      onClick={() => handleBookingStatusChange(booking.id, 'rejected')}
                      variant="contained"
                      sx={{
                        backgroundColor: '#fb2f60',
                        '&:hover': { backgroundColor: '#c0332b' },
                        color: 'white',
                      }}
                    >
                      Reject
                    </Button>
                  </Stack>
                )}
              </Paper>
            </ListItem>
          ))
        ) : (
          <Typography>No bookings found.</Typography>
        )}
      </List>
    </Container>
  );
};

export default MentorDashboard;
