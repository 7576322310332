import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Box, Typography, TextField, Chip, Grid, Card, Avatar, MenuItem, Select, FormControl, InputLabel, InputAdornment } from '@mui/material';
import axios from 'axios';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import '@fontsource/lato'; // Import Lato font

// Styling for the search bar container
const SearchBarContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '24px',
  border: '1px solid #dcdcdc',
  padding: '0.5rem',
  backgroundColor: '#fff',
});

// Styling for the search input field
const SearchInput = styled(TextField)({
  flexGrow: 1,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
});

// Styling for the expertise selection dropdown
const ExpertiseSelect = styled(Select)({
  minWidth: 150,
  backgroundColor: '#ffebf0',
  color: '#000000',
  borderRadius: '10px',
  '& .MuiSelect-icon': {
    color: '#fff',
  },
  '& .MuiInputLabel-outlined': {
    color: '#fff',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiMenu-paper': {
    borderRadius: '8px',
  },
});

// MentorSearch component
const MentorSearch = () => {
  const [mentors, setMentors] = useState([]);
  const [filteredMentors, setFilteredMentors] = useState([]);
  const [expertiseOptions, setExpertiseOptions] = useState([]);
  const [expertise, setExpertise] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const scrollContainerRef = useRef(null);
  const intervalRef = useRef(null);

  const fetchMentors = useCallback(async () => {
    try {
      const response = await axios.get('http://52.90.169.75:8000/api/mentors/');
      setMentors(response.data);
      setFilteredMentors(response.data);
    } catch (error) {
      console.error('Error fetching mentors:', error);
    }
  }, []);

  const fetchExpertiseData = useCallback(async () => {
    try {
      const response = await axios.get('http://52.90.169.75:8000/api/expertise/');
      setExpertiseOptions(response.data);
    } catch (error) {
      console.error('Error fetching expertise options:', error);
    }
  }, []);

  const filterMentors = useCallback((searchTerm, expertise) => {
    let filtered = mentors.filter(mentor =>
      mentor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      mentor.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      mentor.expertise.some(exp => exp.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    if (expertise) {
      filtered = filtered.filter(mentor => mentor.expertise.some(exp => exp.name === expertise));
    }
    setFilteredMentors(filtered);
  }, [mentors]);

  useEffect(() => {
    fetchMentors();
    fetchExpertiseData();
  }, [fetchMentors, fetchExpertiseData]);

  useEffect(() => {
    filterMentors(searchTerm, expertise);
  }, [searchTerm, expertise, filterMentors]);

  useEffect(() => {
    const startScrolling = () => {
      intervalRef.current = setInterval(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollBy({ top: 1, behavior: 'smooth' });
          if (scrollContainerRef.current.scrollTop + scrollContainerRef.current.clientHeight >= scrollContainerRef.current.scrollHeight) {
            scrollContainerRef.current.scrollTop = 0;
          }
        }
      }, 5);
    };

    startScrolling();

    return () => clearInterval(intervalRef.current);
  }, [filteredMentors.length]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleExpertiseChange = (e) => {
    setExpertise(e.target.value);
  };

  const [text] = useTypewriter({
    words: ['Design', 'Marketing', 'Startup', 'Funding Startup'],
    loop: true,
    delaySpeed: 5000,
  });

  return (
    <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'space-between', mt: 20 }}>
      <Box sx={{ flex: 3, mr: 6 }}>
        {/* Title and Typewriter effect */}
        <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Lato', color: 'white' }}>
          1-on-1 <span style={{ color: '#ec2d5b' }}>{text}</span> <Cursor />
          Mentorship
        </Typography>
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Lato', color: 'white' }}>
          Learn a new skill, launch a project, land your dream career.
        </Typography>
        <Box display="flex" flexDirection="column" mt={3}>
          <SearchBarContainer>
            <SearchInput
              variant="outlined"
              placeholder="Search by company, skills or role"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl variant="outlined" sx={{ minWidth: 100, ml: 1 }}>
              <InputLabel>Expertise</InputLabel>
              <ExpertiseSelect
                value={expertise}
                onChange={handleExpertiseChange}
                label="Expertise"
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {expertiseOptions.map(option => (
                  <MenuItem key={option.id} value={option.name}>{option.name}</MenuItem>
                ))}
              </ExpertiseSelect>
            </FormControl>
          </SearchBarContainer>
          <Box mt={2}>
            {expertiseOptions.map(option => (
              <Chip
  key={option.id}
  label={option.name}
  onClick={() => setExpertise(expertise === option.name ? '' : option.name)}
  sx={{ 
    margin: 0.5, 
    cursor: 'pointer', 
    color: 'white', 
    fontFamily: 'Lato', 
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-100%',
      width: '200%',
      height: '100%',
      background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%)',
      transition: 'all 0.5s ease',
      animation: 'shine 2s infinite',
    },
    '@keyframes shine': {
      '0%': {
        left: '-100%',
      },
      '100%': {
        left: '100%',
      },
    },
  }}
  color={expertise === option.name ? 'primary' : 'default'}
/>

            ))}
          </Box>
        </Box>
        {/* Stats Section */}
        <Box mt={4} sx={{ display: 'flex', justifyContent: 'space-around', color: 'white', fontFamily: 'Lato' }}>
          <Box textAlign="center">
            <Typography variant="h5">4,900+</Typography>
            <Typography variant="body1">Available mentors</Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="h5">19,800+</Typography>
            <Typography variant="body1">Matches made</Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="h5">130+</Typography>
            <Typography variant="body1">Countries represented</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 2, height: '600px', overflowY: 'hidden', position: 'relative' }}>
        <Box
          ref={scrollContainerRef}
          onMouseEnter={() => clearInterval(intervalRef.current)}
          onMouseLeave={() => {
            intervalRef.current = setInterval(() => {
              if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollBy({ top: 1, behavior: 'auto' });
                if (scrollContainerRef.current.scrollTop + scrollContainerRef.current.clientHeight >= scrollContainerRef.current.scrollHeight) {
                  scrollContainerRef.current.scrollTop = 0;
                }
              }
            }, 20);
          }}
          sx={{
            height: '100%',
            overflowY: 'auto',
            scrollbarWidth: 'none', /* Firefox */
            '&::-webkit-scrollbar': {
              display: 'none', /* Chrome, Safari, Opera */
            },
          }}
        >
          <Grid container spacing={4}>
            {filteredMentors.concat(filteredMentors).map((mentor, index) => ( // Concatenate the list to repeat profiles
              <Grid item xs={12} key={index}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 2, borderRadius: '8px', padding: '20px' }}>
                  <Avatar src={mentor.profile_picture || "/default-avatar.png"} alt={mentor.name} sx={{ width: 64, height: 64, mr: 2 }} />
                  <Box sx={{ flexGrow: 1, color: 'white', fontFamily: 'Lato' }}>
                    <Typography variant="h6" sx={{ color: '#590119' }}>{mentor.name}</Typography>
                    <Typography variant="body2" color="#590119">{mentor.description}</Typography>
                    <Box mt={1}>
                      {mentor.expertise.map((exp, index) => (
                        <Chip key={index} label={exp.name} sx={{ mr: 1, mb: 1, color: 'white',backgroundColor:'#590119', fontFamily: 'Lato' }} />
                      ))}
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default MentorSearch;
