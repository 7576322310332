import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Avatar, Typography, Box, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // For navigation
import { fetchMenteeBookings } from '../services/api';
import Layout from './Layout'; // Assuming your Layout component is in the same folder

const MenteeBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleCount, setVisibleCount] = useState(4); // Controls how many bookings are visible initially

  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bookingsData = await fetchMenteeBookings();
        setBookings(bookingsData);
      } catch (err) {
        setError('Failed to fetch bookings');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const loadMoreBookings = () => {
    setVisibleCount((prevCount) => prevCount + 4); // Increase visible bookings by 4 on each button click
  };

  const handleCardClick = (bookingId) => {
    navigate(`/booking-details/${bookingId}`); // Navigate to a specific page when a button is clicked
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography variant="body1" color="error">{error}</Typography>;
  }

  return (
    <Layout>
      <Typography variant="h4" gutterBottom>Your Bookings</Typography>
      <Grid container spacing={3} justifyContent="center"> {/* Increased spacing to 3 */}
        {bookings.slice(0, visibleCount).map((booking) => (
          <Grid item key={booking.id}>
            <Card
              sx={{
                padding: 2,
                borderRadius: '20px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Softer shadow for better look
                width: '250px', // Set card width to 250px
                height: '100%',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)', // Slight hover effect
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', // Shadow grows on hover
                },
              }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Avatar
                  src={booking.mentor_profile_picture} // Mentor image from booking data
                  alt={booking.mentor}
                  sx={{ width: 80, height: 80, marginBottom: 2 }}
                />
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                  {booking.mentor_name}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>Date: {booking.booking_date}</Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>Time: {booking.booking_start_time} - {booking.booking_end_time}</Typography>
                <Button
                  variant="contained"
                  sx={{ mt: 2, backgroundColor: '#ec2d5b', '&:hover': { backgroundColor: '#d1274f' } }} // Custom color for the button
                  onClick={() => handleCardClick(booking.id)}
                >
                  View Booking Details
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {visibleCount < bookings.length && (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button variant="contained" onClick={loadMoreBookings} sx={{ backgroundColor: '#ec2d5b' }}>
            Load More
          </Button>
        </Box>
      )}
    </Layout>
  );
};

export default MenteeBookings;
