import React, { useState, useEffect } from 'react';
import { Card, CardContent, Avatar, Typography, Box, Divider, CircularProgress } from '@mui/material';
import axios from 'axios';

const MenteeProfileCard = ({ menteeId, onClose }) => {
  const [mentee, setMentee] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMenteeProfile = async () => {
      const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
      if (!token) {
        console.error('No token found in localStorage');
        setLoading(false);
        return;
      }
      
      try {
        const response = await axios.get(`http://52.90.169.75:8000/api/mentee-profile/${menteeId}/`, {
          headers: { Authorization: `Token ${token}` },
        });
        console.log('API Response:', response.data); // Log the API response for debugging
        setMentee(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching mentee profile:', error.response ? error.response.data : error.message);
        setLoading(false);
      }
    };
  
    fetchMenteeProfile();
  }, [menteeId]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!mentee) {
    return (
      <Typography variant="h6" color="error">
        Error loading mentee data
      </Typography>
    );
  }

  return (
    <Card sx={{ maxWidth: 400, padding: 2, position: 'relative' }}>
      {/* Close button */}
      <Box
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          cursor: 'pointer',
          fontSize: '20px',
          color: '#fb2f60',
        }}
        onClick={onClose}
      >
        &#10006;
      </Box>

      {/* Mentee Avatar and Info */}
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <Avatar
          alt={`${mentee.first_name || 'N/A'} ${mentee.last_name || 'N/A'}`}
          src={mentee.profile_picture || '/default-avatar.jpg'}
          sx={{ width: 72, height: 72, marginRight: 2 }}
        />
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {mentee.first_name || 'N/A'} {mentee.last_name || 'N/A'}
          </Typography>
          <Typography variant="body1">{mentee.email || 'N/A'}</Typography>
          <Typography variant="body2">{mentee.phone_number || 'N/A'}</Typography>
        </Box>
      </Box>

      <Divider />

      {/* Additional Information */}
      <CardContent>
        <Typography variant="body1" gutterBottom>
          <strong>Address:</strong> {mentee.address || 'N/A'}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>City:</strong> {mentee.city || 'N/A'}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>State:</strong> {mentee.state || 'N/A'}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Country:</strong> {mentee.country || 'N/A'}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MenteeProfileCard;
