import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography, Grid, Divider } from '@mui/material';

const OTPVerification = () => {
  const [formData, setFormData] = useState({ username: '', otp_code: '' });
  const [message, setMessage] = useState('');
  const [logo, setLogo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();  // Get state passed from registration

  // Set the username from the previous page if available
  useEffect(() => {
    if (location.state?.username) {
      setFormData((prev) => ({ ...prev, username: location.state.username }));
    }
  }, [location.state]);

  // Fetch the logo from API
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/navigation/api/menus/');
        setLogo(response.data.logos.normal_logo);
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://52.90.169.75:8000/api/auth/verify-otp/', formData);
      navigate('/login');
    } catch (error) {
      setMessage('OTP Verification failed.');
      console.error('OTP Verification Error:', error);
    }
  };

  return (
    <Grid container sx={{
      minHeight: '100vh',
      background: 'linear-gradient(135deg, #c0332b 0%, #fed7db 100%)',
      backgroundImage: `
        radial-gradient(at 10% 20%, rgba(192, 51, 43, 0.3) 0%, transparent 50%),
        radial-gradient(at 80% 80%, rgba(88, 0, 23, 0.4) 0%, transparent 50%),
        radial-gradient(at 50% 50%, rgba(251, 47, 96, 0.2) 0%, transparent 40%),
        radial-gradient(at 90% 10%, rgba(254, 215, 219, 0.3) 0%, transparent 50%)
      `,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      {/* Left Section - OTP Form */}
      <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box
          sx={{
            width: '80%',
            maxWidth: '400px',
            backgroundColor: '#fff',
            padding: '40px',
            borderRadius: '12px',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
            position: 'relative',
          }}
        >
          {/* Display Logo */}
          {logo && (
            <Box sx={{ textAlign: 'center', mb: 4 }}>
              <img
                src={logo}
                alt="Logo"
                style={{ height: '40px', marginBottom: '20px' }}
              />
            </Box>
          )}

          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
            Verify your OTP
          </Typography>

          {message && <Typography variant="body1" color="error" sx={{ textAlign: 'center', mb: 2 }}>{message}</Typography>}

          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="OTP Code"
              name="otp_code"
              value={formData.otp_code}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 2,
                backgroundColor: '#fb2f60',
                '&:hover': {
                  backgroundColor: '#c0332b',
                },
                padding: '10px',
                fontWeight: 'bold',
              }}
              type="submit"
            >
              Verify OTP
            </Button>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <Typography variant="body2" color="textSecondary">
              Haven't received the OTP?{' '}
              <Typography
                component="span"
                sx={{ textDecoration: 'underline', cursor: 'pointer', color: '#fb2f60' }}
                onClick={() => navigate('/resend-otp')}
              >
                Resend OTP
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* Right Section - Quote */}
      <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ padding: '40px', textAlign: 'center', color: '#000000' }}>
          <Typography variant="h6" sx={{ fontStyle: 'italic', mb: 2 }}>
            “Before Menttalk, I used to research problems myself, but always found it hard to relate learnings back to my exact challenge.”
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Dipanshu Chauhan
          </Typography>
          <Typography variant="body2">
            Co-Founder at Lovelyrush
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OTPVerification;
