import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import axios from 'axios';

// Zigzag styling and background effect for the component
const waveBackground = {
  background: 'linear-gradient(360deg, #580017 0%, #c0332b 100%)', // Gradient background
  position: 'relative',
  overflow: 'hidden',
  padding: '50px 0',
};

// Dotted lines connecting the cards
const dottedLineStyle = {
  width: '2px',
  height: '50px',
  background: 'white',
  margin: '0 auto',
};

// AboutCoreValues component
const AboutCoreValues = () => {
  const [coreValues, setCoreValues] = useState([]);

  useEffect(() => {
    const fetchCoreValues = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/about/1'); // Replace with correct API URL
        setCoreValues(response.data.core_values);
      } catch (error) {
        console.error('Error fetching core values:', error);
      }
    };
    fetchCoreValues();
  }, []);

  return (
    <Box sx={waveBackground}>
      <Typography
        variant="h3"
        sx={{
          color: '#fff',
          textAlign: 'center',
          fontWeight: 'bold',
          mb: 4,
        }}
      >
        Our Core Values
      </Typography>

      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        {coreValues.map((value, index) => (
          <Grid
            item
            key={index}
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              marginBottom: index < coreValues.length - 1 ? '50px' : '0px', // Space between cards
            }}
          >
            {/* Card */}
            <Card
              sx={{
                backgroundColor: '#fff',
                width: '280px', // Smaller, compact width
                boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                borderRadius: '12px',
                textAlign: 'left',
              }}
            >
              <CardContent>
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                  {value.heading}
                </Typography>
                <Typography variant="body1">{value.description}</Typography>
              </CardContent>
            </Card>

            {/* Dots connecting the cards */}
            {index < coreValues.length - 1 && (
              <Box sx={{ position: 'absolute', bottom: '-50px', left: '50%', transform: 'translateX(-50%)' }}>
                <Box sx={dottedLineStyle} />
                <Box
                  sx={{
                    width: '10px',
                    height: '10px',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    margin: '10px auto',
                  }}
                />
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AboutCoreValues;
