import React, { useState, useEffect } from 'react';
import { fetchCohorts, createCohortRegistration, updatePaymentStatus, verifyPayment } from '../services/api'; // Import necessary functions

const Cohorts = () => {
  const [cohorts, setCohorts] = useState([]);
  const [loading, setLoading] = useState(false); // To manage loading state
  const [error, setError] = useState(null); // To manage errors

  useEffect(() => {
    loadCohorts();
  }, []);

  const loadCohorts = async () => {
    try {
      setLoading(true);
      const cohortsData = await fetchCohorts();
      setCohorts(cohortsData);
    } catch (error) {
      setError('Error fetching cohorts. Please try again later.');
      console.error('Error fetching cohorts:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBooking = async (cohortId) => {
    try {
      const { registration_id, razorpay_order_id, amount } = await createCohortRegistration(cohortId);
  
      const options = {
        key: 'rzp_test_GY4iJFc1dQJzvQ', // Replace with your Razorpay API key
        amount: amount * 100, // Razorpay accepts amount in paise
        currency: 'INR',
        name: 'Your Company Name',
        description: 'Cohort Registration',
        order_id: razorpay_order_id,
        handler: async function (response) {
          console.log('Razorpay Response:', response); // Add this line to debug the Razorpay response

          try {
            // First, update the payment status in the backend
            await updatePaymentStatus(registration_id, response.razorpay_payment_id, response.razorpay_signature);
  
            // Then, verify the payment with Razorpay
            await verifyPayment({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            });
  
            alert('Payment verified and registration completed.');
          } catch (error) {
            console.error('Payment verification failed:', error);
            alert('Payment verification failed. Please contact support.');
          }
        },
        prefill: {
          name: 'User Name', // Fetch this from the user's session or profile
          email: 'user@example.com', // Fetch this from the user's session or profile
        },
        notes: {
          address: 'Corporate Office',
        },
        theme: {
          color: '#3399cc',
        },
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error during payment process:', error.response.data);
      alert('Error during payment process. Please try again.');
    }
  };
  
  return (
    <div>
      <h1>Cohorts</h1>
      {loading ? (
        <p>Loading cohorts...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ul>
          {cohorts.map((cohort) => (
            <li key={cohort.id}>
              <h2>{cohort.name}</h2>
              <p>{cohort.description}</p>
              <button onClick={() => handleBooking(cohort.id)}>Register</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Cohorts;
