// src/pages/ContactPage.js
import React, { useEffect, useState } from 'react';
import { fetchContactPage } from '../services/api';
import Header from '../components/Header';
import Navbar from '../components/NavBar';

const ContactPage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const contactPageData = await fetchContactPage();
      setData(contactPageData);
    };

    getData();
  }, []);

  if (!data) return <div>Loading...</div>;

  return (
    <div>
      <Header />
      <Navbar />
      <h1>{data.title}</h1>
      <p>{data.description}</p>
      <div>
        <h2>Contact Information</h2>
        <p>Address: {data.address}</p>
        <p>Email: {data.email}</p>
        <p>Phone: {data.phone}</p>
      </div>
    </div>
  );
};

export default ContactPage;
