import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Grid, Button, Container } from '@mui/material';
import Slider from 'react-slick';
import axios from 'axios';

// Import CSS files for react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Colors for the theme
const themeColors = {
  primary: '#590119',
  accent: '#fff',
  background: '#F4F6F8',  // Light background
  cardBackground: '#ffffff',  // White cards for contrast
  textPrimary: '#333333',  // Dark primary text color
  textSecondary: '#555555',  // Secondary text color
};

const OurMentors = () => {
  const [mentorData, setMentorData] = useState([]);

  // Fetch data from the home API, using top_mentors key
  useEffect(() => {
    const fetchMentorData = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/home/1/');
        setMentorData(response.data.top_mentors);  // Use top_mentors array
      } catch (error) {
        console.error('Error fetching mentor data:', error);
      }
    };
    fetchMentorData();
  }, []);

  // Continuous slider settings with no interval and infinite scroll
  const mentorSliderSettings = {
    dots: false,
    infinite: true,
    speed: 4000,  // Controls the speed of transition
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,  // Continuous sliding with no pause
    cssEase: 'linear',  // Linear easing for smooth, continuous scrolling
    pauseOnHover: false, // Keep sliding even when hovered
    swipeToSlide: true,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 600, settings: { slidesToShow: 1 } }
    ]
  };

  return (
    <Box sx={{ backgroundColor: themeColors.background, color: themeColors.textPrimary, py: 5, width: '100%' }}>
      <Container maxWidth={false} disableGutters>
        {/* Top Informational Section */}
        <Box sx={{ textAlign: 'center', mb: 5 }}>
          <Typography variant="h6" sx={{ color: themeColors.primary }}>Move Over traditional courses</Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: themeColors.textPrimary }}>
            Start Making Progress with 1:1 Long Term Mentorship
          </Typography>
        </Box>

        {/* Static Information Cards */}
        <Grid container spacing={3} justifyContent="center" sx={{ mb: 5 }}>
          <Grid item xs={12} sm={4}>
            <Card sx={{ position: 'relative', marginLeft: 2, marginRight: 2, padding: 5, borderRadius: 5, textAlign: 'center', backgroundColor: themeColors.cardBackground, boxShadow: 3 }}>
              {/* Large background number */}
              <Typography
                variant="h1"
                sx={{
                  position: 'absolute',
                  top: '-20px',
                  left: '10px',
                  fontSize: '120px',
                  color: 'rgba(0, 0, 0, 0.05)',
                  fontWeight: 'bold',
                  zIndex: 1
                }}
              >
                30
              </Typography>
              <Typography variant="h2" sx={{ fontWeight: 'bold', color: themeColors.primary, zIndex: 2 }}>30%</Typography>
              <Typography variant="h6" sx={{ color: themeColors.textPrimary, zIndex: 2 }}>Cheaper</Typography>
              <Typography variant="body2" sx={{ color: themeColors.textSecondary, zIndex: 2 }}>Compared to any Mentorship Drives</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ position: 'relative', marginLeft: 2, marginRight: 2, padding: 5, borderRadius: 5, textAlign: 'center', backgroundColor: themeColors.cardBackground, boxShadow: 3 }}>
              {/* Large background number */}
              <Typography
                variant="h1"
                sx={{
                  position: 'absolute',
                  top: '-20px',
                  left: '10px',
                  fontSize: '120px',
                  color: 'rgba(0, 0, 0, 0.05)',
                  fontWeight: 'bold',
                  zIndex: 1
                }}
              >
                4
              </Typography>
              <Typography variant="h2" sx={{ fontWeight: 'bold', color: themeColors.primary, zIndex: 2 }}>4x</Typography>
              <Typography variant="h6" sx={{ color: themeColors.textPrimary, zIndex: 2 }}>Results</Typography>
              <Typography variant="body2" sx={{ color: themeColors.textSecondary, zIndex: 2 }}>As compared to any online Mentorship Platform</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ position: 'relative', marginLeft: 2, marginRight: 2, padding: 5, borderRadius: 5, textAlign: 'center', backgroundColor: themeColors.cardBackground, boxShadow: 3 }}>
              {/* Large background number */}
              <Typography
                variant="h1"
                sx={{
                  position: 'absolute',
                  top: '-20px',
                  left: '10px',
                  fontSize: '120px',
                  color: 'rgba(0, 0, 0, 0.05)',
                  fontWeight: 'bold',
                  zIndex: 1
                }}
              >
                50
              </Typography>
              <Typography variant="h2" sx={{ fontWeight: 'bold', color: themeColors.primary, zIndex: 2 }}>50%</Typography>
              <Typography variant="h6" sx={{ color: themeColors.textPrimary, zIndex: 2 }}>Faster</Typography>
              <Typography variant="body2" sx={{ color: themeColors.textSecondary, zIndex: 2 }}>Get your Planning and Presentation Done ASAP</Typography>
            </Card>
          </Grid>
        </Grid>

        {/* Mentor Slider Section */}
        <Slider {...mentorSliderSettings}>
          {mentorData.map((mentor) => (
            <Card
              key={mentor.name}
              sx={{
                m: 2,
                borderRadius: '16px',
                maxWidth: 300,
                backgroundColor: themeColors.cardBackground,
                color: themeColors.textPrimary,
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0px 4px 20px rgba(0,0,0,0.2)',
                },
              }}
            >
              <Box sx={{ position: 'relative' }}>
                <CardMedia
                  component="img"
                  image={mentor.profile_picture || "/default-avatar.png"}
                  alt={mentor.name}
                  sx={{
                    objectFit: 'cover',
                    width: '100%',
                    height: 250,
                    borderRadius: '16px 16px 0 0',
                  }}
                />
                {/* Play Button */}
                <Button
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    color: themeColors.accent,
                    borderRadius: '50%',
                    padding: 2,
                    '&:hover': {
                      backgroundColor: themeColors.primary,
                    }
                  }}
                >
                  ▶
                </Button>
              </Box>
              <CardContent>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: themeColors.primary }}>
                  {mentor.name}
                </Typography>
                <Typography variant="body2" sx={{ color: themeColors.textSecondary }}>
                  {mentor.description}
                </Typography>
                <Box mt={2}>
                  {mentor.expertise.map((exp, index) => (
                    <Typography key={index} variant="caption" sx={{ display: 'inline-block', backgroundColor: themeColors.primary, color: themeColors.accent, padding: '4px 8px', borderRadius: '8px', mr: 1 }}>
                      {exp.name}
                    </Typography>
                  ))}
                </Box>
              </CardContent>
            </Card>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default OurMentors;
