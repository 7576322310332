// src/pages/BrowseMentor.js
import React from 'react';
import BrowseMentors from '../components/BrowseMentors'; // Assuming the component is in the components folder
import LightNavBar from '../components/LightNavBar';

const BrowseMentor = () => {
  return (
    <div>
    <LightNavBar/>
      <BrowseMentors />
    </div>
  );
};

export default BrowseMentor;
