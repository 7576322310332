import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Toolbar, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookingsIcon from '@mui/icons-material/EventAvailable';
import BookIcon from '@mui/icons-material/Book';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People'; // Community section
import GroupIcon from '@mui/icons-material/Group';  // Cohorts section
import AvailabilityIcon from '@mui/icons-material/AccessTime'; // Availability section

const Sidebar = () => {
  const userType = localStorage.getItem('user_type');

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <List>
        {/* Sidebar for Mentee */}
        {userType === 'mentee' && (
          <>
            {/* Dashboard Link */}
            <ListItem button component={Link} to="/dashboard">
              <ListItemIcon><DashboardIcon /></ListItemIcon>
              <ListItemText primary="Dashboard" sx={{ fontFamily: 'SuisseIntl-SemiBold' }} />
            </ListItem>

            {/* My Bookings Link */}
            <ListItem button component={Link} to="/mentee-bookings">
              <ListItemIcon><BookingsIcon /></ListItemIcon>
              <ListItemText primary="My Bookings" sx={{ fontFamily: 'SuisseIntl-SemiBold' }} />
            </ListItem>

            {/* My Events Link */}
            <ListItem button component={Link} to="/mentee-events">
              <ListItemIcon><BookIcon /></ListItemIcon>
              <ListItemText primary="My Events" sx={{ fontFamily: 'SuisseIntl-Light' }} />
            </ListItem>

            {/* My Cohorts Link */}
            <ListItem button component={Link} to="/mentee-cohorts">
              <ListItemIcon><GroupIcon /></ListItemIcon>
              <ListItemText primary="My Cohorts" sx={{ fontFamily: 'SuisseIntl-Light' }} />
            </ListItem>

            {/* Break/Spacer */}
            <Divider sx={{ my: 2 }} />

            {/* Community Section */}
            <ListItem>
              <Typography variant="subtitle2" sx={{ fontFamily: 'SuisseIntl-SemiBold', px: 2, color: '#333' }}>
                Community
              </Typography>
            </ListItem>
            <ListItem button component={Link} to="/community">
              <ListItemIcon><PeopleIcon /></ListItemIcon>
              <ListItemText primary="Community" sx={{ fontFamily: 'SuisseIntl-Light' }} />
            </ListItem>

            {/* My Profile Link */}
            <ListItem button component={Link} to="/profile">
              <ListItemIcon><PersonIcon /></ListItemIcon>
              <ListItemText primary="My Profile" sx={{ fontFamily: 'SuisseIntl-Light' }} />
            </ListItem>
          </>
        )}

        {/* Sidebar for Mentor */}
        {userType === 'mentor' && (
          <>
            {/* Mentor Dashboard Link */}
            <ListItem button component={Link} to="/dashboard">
              <ListItemIcon><DashboardIcon /></ListItemIcon>
              <ListItemText primary="Mentor Dashboard" sx={{ fontFamily: 'SuisseIntl-SemiBold' }} />
            </ListItem>

            {/* Mentor Profile Link */}
            <ListItem button component={Link} to="/profile">
              <ListItemIcon><PersonIcon /></ListItemIcon>
              <ListItemText primary="Mentor Profile" sx={{ fontFamily: 'SuisseIntl-Light' }} />
            </ListItem>

            {/* Mentor Bookings Link */}
            <ListItem button component={Link} to="/bookings">
              <ListItemIcon><BookingsIcon /></ListItemIcon>
              <ListItemText primary="Mentor Bookings" sx={{ fontFamily: 'SuisseIntl-Light' }} />
            </ListItem>

            {/* Mentor Availabilities Link */}
            <ListItem button component={Link} to="/availabilities">
              <ListItemIcon><AvailabilityIcon /></ListItemIcon>
              <ListItemText primary="Mentor Availabilities" sx={{ fontFamily: 'SuisseIntl-Light' }} />
            </ListItem>
          </>
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
