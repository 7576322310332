import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Typography, TextField, Box, Card, CardContent, Grid } from '@mui/material';
import { fetchMentor, bookMentor, updateBookingPaymentStatus, verifyBookingPayment } from '../services/api'; // Import the necessary functions

const BookMentor = () => {
  const { mentorId } = useParams();
  const [mentor, setMentor] = useState(null);
  const [selectedTimeBlock, setSelectedTimeBlock] = useState('');
  const [selectedAvailability, setSelectedAvailability] = useState(''); // Added for availability
  const [isBooking, setIsBooking] = useState(false);

  const fetchMentorData = useCallback(async () => {
    try {
      const mentorData = await fetchMentor(mentorId);
      setMentor(mentorData);
    } catch (error) {
      console.error('Error fetching mentor:', error);
    }
  }, [mentorId]);

  useEffect(() => {
    fetchMentorData();
  }, [fetchMentorData]);

  const handleBooking = async () => {
    if (!selectedTimeBlock || !selectedAvailability) {
      alert('Please select a time block and availability.');
      return;
    }

    setIsBooking(true);

    try {
      const response = await bookMentor({
        mentorId,
        timeBlockId: selectedTimeBlock.time_block.id,
        availabilityId: selectedAvailability,
      });

      const { booking_id, razorpay_order_id } = response;

      // Extract the price from the selected time block
      const amount = selectedTimeBlock.price;

      // Razorpay payment options
      const options = {
        key: 'rzp_test_GY4iJFc1dQJzvQ', // Replace with your Razorpay API key
        amount: amount * 100, // Convert amount to paise
        currency: 'INR',
        name: mentor.name,
        description: `Booking for ${selectedTimeBlock.time_block.duration} minutes`,
        order_id: razorpay_order_id,
        handler: async function (response) {
          try {
            await updateBookingPaymentStatus(booking_id, response.razorpay_payment_id);
            await verifyBookingPayment({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            });
            alert('Payment successful and booking confirmed');
          } catch (error) {
            console.error('Payment verification failed:', error);
            alert('Payment verification failed. Please contact support.');
          }
        },
        prefill: {
          name: 'User Name', // Replace with actual user name
          email: 'user@example.com', // Replace with actual user email
        },
        notes: {
          mentor: mentor.name,
          time_block: `${selectedTimeBlock.time_block.duration} minutes`,
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error during booking process:', error);
      alert('Error during booking process. Please try again.');
    } finally {
      setIsBooking(false);
    }
  };

  if (!mentor) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              {mentor.profile_picture ? (
                <img src={mentor.profile_picture} alt={mentor.name} style={{ width: '100%', borderRadius: '8px' }} />
              ) : (
                <div style={{ width: '100%', height: '200px', backgroundColor: '#ccc', borderRadius: '8px' }} />
              )}
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="h4" gutterBottom>{mentor.name}</Typography>
              <Typography variant="body1" paragraph>{mentor.bio}</Typography>
              <Typography variant="h6" gutterBottom>Expertise:</Typography>
              <ul>
                {mentor.expertise.map((exp) => (
                  <li key={exp.name}>{exp.name}</li>
                ))}
              </ul>
              <Typography variant="h6" gutterBottom>Tools:</Typography>
              <ul>
                {mentor.toolkits_used.map((tool) => (
                  <li key={tool.name}>{tool.name}</li>
                ))}
              </ul>
              <Typography variant="h6" gutterBottom>LinkedIn:</Typography>
              <a href={mentor.linkedin} target="_blank" rel="noopener noreferrer">
                {mentor.linkedin}
              </a>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Typography variant="h5" style={{ marginTop: '20px' }}>Select a Time Block</Typography>
      <TextField
        select
        label="Time Block"
        value={selectedTimeBlock ? selectedTimeBlock.time_block.id : ''}
        onChange={(e) => {
          const block = mentor.mentor_time_blocks.find(tb => tb.time_block.id === parseInt(e.target.value));
          setSelectedTimeBlock(block);
        }}
        SelectProps={{
          native: true,
        }}
        fullWidth
        style={{ marginBottom: '20px' }}
      >
        <option value="">Select a time block</option>
        {mentor.mentor_time_blocks.map((block) => (
          <option key={block.time_block.id} value={block.time_block.id}>
            {block.time_block.duration} minutes - ₹{block.price}
          </option>
        ))}
      </TextField>

      <Typography variant="h5" style={{ marginTop: '20px' }}>Select Availability</Typography>
      <TextField
        select
        label="Availability"
        value={selectedAvailability}
        onChange={(e) => setSelectedAvailability(e.target.value)}
        SelectProps={{
          native: true,
        }}
        fullWidth
        style={{ marginBottom: '20px' }}
      >
        <option value="">Select an availability</option>
        {mentor.availabilities.map((availability) => (
          <option key={availability.id} value={availability.id}>
            {availability.date} {availability.start_time} - {availability.end_time}
          </option>
        ))}
      </TextField>

      <Button
        variant="contained"
        color="primary"
        onClick={handleBooking}
        disabled={!selectedTimeBlock || !selectedAvailability || isBooking}
      >
        {isBooking ? 'Processing...' : 'Book'}
      </Button>
    </Box>
  );
};

export default BookMentor;
