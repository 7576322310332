import React, { useEffect, useState } from 'react';
import { Container, Box, Grid, Typography, Card, CardContent } from '@mui/material';
import axios from 'axios';

// Define theme colors (if needed)
const themeColors = {
  primary: '#590119',
  accent: '#ec2d5b',
  background: '#FAFAFA',
};

// Component for mentorship categories
const MentorshipCategories = () => {
  const [categories, setCategories] = useState([]);

  // Fetch data from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/home/1/');
        setCategories(response.data.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <Container sx={{ py: 8 }}>
      <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 'bold', mb: 4, color: themeColors.primary }}>
        Every Domain Every Industry Covered
      </Typography>
      <Typography variant="body1" sx={{ textAlign: 'center', mb: 6 }}>
        Our mentors are equipped to guide you in any field you're passionate about
      </Typography>

      {/* Grid Layout */}
      <Grid container spacing={4}>
        {categories.map((category) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={category.name}>
            <Card
              sx={{
                textAlign: 'center',
                boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                borderRadius: '16px',
                padding: 2,
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0px 4px 20px rgba(0,0,0,0.2)',
                },
                transition: 'transform 0.2s ease',
              }}
            >
              <CardContent>
                {/* Icon */}
                <Box
                  component="img"
                  src={category.icon || '/default-category-icon.png'}
                  alt={category.name}
                  sx={{ width: 40, height: 40, margin: 'auto', marginBottom: 2 }}
                />

                {/* Category Name */}
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: themeColors.primary }}>
                  {category.name}
                </Typography>

                {/* Mentors Count */}
                <Typography variant="body2" color="textSecondary">
                  Browse {Math.floor(Math.random() * 100) + 1}+ Mentors {/* Example for mentor count */}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default MentorshipCategories;
