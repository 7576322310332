import React, { useEffect, useState } from 'react';
import {
  fetchLoggedInMentorProfile,
  updateMentorProfile,
  fetchExpertiseOptions,
  fetchToolOptions,
  fetchTimeBlockOptions,
} from '../services/api';
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Grid,
  TextField,
  Button,
  Chip,
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const MentorProfile = () => {
  const [mentor, setMentor] = useState(null);
  const [editMode, setEditMode] = useState({
    group1: false,
    group2: false,
    group3: false,
    group4: false,
  });
  const [expertiseOptions, setExpertiseOptions] = useState([]);
  const [toolOptions, setToolOptions] = useState([]);
  const [timeBlockOptions, setTimeBlockOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    bio: '',
    expertise: [],
    toolkits_used: [],
    work_experiences: [],
    linkedin: '',
    mentor_time_blocks: [],
  });
  const [newExperience, setNewExperience] = useState({
    company_name: '',
    work_description: '',
    date_started: null,
    date_ended: null,
    currently_working: false,
    industry_expertise: [],
  });
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [mentorData, expertiseData, toolData, timeBlockData] = await Promise.all([
          fetchLoggedInMentorProfile(),
          fetchExpertiseOptions(),
          fetchToolOptions(),
          fetchTimeBlockOptions(),
        ]);

        setMentor(mentorData);
        setExpertiseOptions(expertiseData);
        setToolOptions(toolData);
        setTimeBlockOptions(timeBlockData);
        setFormData({
          name: mentorData.name || '',
          description: mentorData.description || '',
          bio: mentorData.bio || '',
          expertise: mentorData.expertise || [],
          toolkits_used: mentorData.toolkits_used || [],
          work_experiences: mentorData.work_experiences || [],
          linkedin: mentorData.linkedin || '',
          mentor_time_blocks: mentorData.mentor_time_blocks.map((block) => ({
            time_block_id: block.time_block.id,
            duration: block.time_block.duration,
            price: block.price,
          })) || [],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async (group) => {
    try {
      const updateData = {};

      switch (group) {
        case 'group1':
          updateData.name = formData.name;
          updateData.description = formData.description;
          updateData.bio = formData.bio;
          break;
        case 'group2':
          updateData.expertise = formData.expertise;
          updateData.toolkits_used = formData.toolkits_used;
          break;
        case 'group3':
          updateData.work_experiences = formData.work_experiences;
          break;
        case 'group4':
          updateData.linkedin = formData.linkedin;
          updateData.mentor_time_blocks = formData.mentor_time_blocks.map((block) => ({
            time_block: block.time_block_id,
            price: block.price,
          }));
          break;
        default:
          return;
      }

      await updateMentorProfile(updateData);
      setMentor({ ...mentor, ...updateData });
      setEditMode({ ...editMode, [group]: false });
    } catch (error) {
      console.error('Error updating mentor profile:', error);
    }
  };

  const handleAutocompleteChange = (name, newValue) => {
    setFormData({ ...formData, [name]: newValue });
  };

  const handleExperienceChange = (index, field, value) => {
    const updatedExperiences = [...formData.work_experiences];
    updatedExperiences[index] = { ...updatedExperiences[index], [field]: value };
    setFormData({ ...formData, work_experiences: updatedExperiences });
  };

  const handleDeleteExperience = (index) => {
    const updatedExperiences = formData.work_experiences.filter((_, i) => i !== index);
    setFormData({ ...formData, work_experiences: updatedExperiences });
  };

  const handleNewExperienceChange = (field, value) => {
    setNewExperience({ ...newExperience, [field]: value });
  };

  const handleAddExperience = () => {
    setFormData({
      ...formData,
      work_experiences: [...formData.work_experiences, newExperience],
    });
    setNewExperience({
      company_name: '',
      work_description: '',
      date_started: null,
      date_ended: null,
      currently_working: false,
      industry_expertise: [],
    });
    setOpenDialog(false);
  };

  const handleTimeBlockChange = (index, field, value) => {
    const updatedTimeBlocks = [...formData.mentor_time_blocks];
    updatedTimeBlocks[index] = { ...updatedTimeBlocks[index], [field]: value };
    setFormData({ ...formData, mentor_time_blocks: updatedTimeBlocks });
  };

  const handleDeleteTimeBlock = (index) => {
    const updatedTimeBlocks = formData.mentor_time_blocks.filter((_, i) => i !== index);
    setFormData({ ...formData, mentor_time_blocks: updatedTimeBlocks });
  };

  const handleAddTimeBlock = () => {
    const newBlock = { time_block_id: '', duration: '', price: '' };
    setFormData({ ...formData, mentor_time_blocks: [...formData.mentor_time_blocks, newBlock] });
  };

  if (!mentor) {
    return <div>Loading...</div>;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" p={2} fontFamily="Poppins">
      <Card sx={{ borderRadius: 2, p: 4, maxWidth: 1200, width: '100%' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card sx={{ borderRadius: 2, mb: 2 }}>
              <CardContent>
                <Typography variant="h6" fontFamily="Poppins">Personal Details</Typography>
                {editMode.group1 ? (
                  <>
                    <TextField
                      fullWidth
                      name="name"
                      label="Name"
                      value={formData.name}
                      onChange={handleInputChange}
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      fullWidth
                      name="description"
                      label="Description"
                      value={formData.description}
                      onChange={handleInputChange}
                      sx={{ mb: 2 }}
                    />
                    <TextField
                      fullWidth
                      name="bio"
                      label="Bio"
                      value={formData.bio}
                      onChange={handleInputChange}
                      sx={{ mb: 2 }}
                    />
                    <Button variant="contained" color="primary" onClick={() => handleSave('group1')} sx={{ mr: 1 }}>
                      Save
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => setEditMode({ ...editMode, group1: false })}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography>Name: {mentor.name}</Typography>
                    <Typography>Description: {mentor.description}</Typography>
                    <Typography>Bio: {mentor.bio}</Typography>
                    <Button variant="contained" color="primary" onClick={() => setEditMode({ ...editMode, group1: true })} sx={{ mt: 2 }}>
                      Edit
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ borderRadius: 2, mb: 2 }}>
              <CardContent>
                <Typography variant="h6" fontFamily="Poppins">Expertise Details</Typography>
                {editMode.group2 ? (
                  <>
                    <Autocomplete
                      multiple
                      options={expertiseOptions}
                      getOptionLabel={(option) => option.name}
                      value={formData.expertise}
                      onChange={(event, newValue) => handleAutocompleteChange('expertise', newValue)}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip key={index} label={option.name} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Select expertise" sx={{ mb: 2 }} />
                      )}
                    />
                    <Autocomplete
                      multiple
                      options={toolOptions}
                      getOptionLabel={(option) => option.name}
                      value={formData.toolkits_used}
                      onChange={(event, newValue) => handleAutocompleteChange('toolkits_used', newValue)}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip key={index} label={option.name} {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Select toolkits" sx={{ mb: 2 }} />
                      )}
                    />
                    <Button variant="contained" color="primary" onClick={() => handleSave('group2')} sx={{ mr: 1 }}>
                      Save
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => setEditMode({ ...editMode, group2: false })}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography>Expertise:</Typography>
                    <Box>
                      {mentor.expertise?.map((exp, index) => (
                        <Chip key={index} label={exp.name} sx={{ mr: 1, mb: 1 }} />
                      ))}
                    </Box>
                    <Typography>Toolkits Used:</Typography>
                    <Box>
                      {mentor.toolkits_used?.map((tool, index) => (
                        <Chip key={index} label={tool.name} sx={{ mr: 1, mb: 1 }} />
                      ))}
                    </Box>
                    <Button variant="contained" color="primary" onClick={() => setEditMode({ ...editMode, group2: true })} sx={{ mt: 2 }}>
                      Edit
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ borderRadius: 2, mb: 2 }}>
              <CardContent>
                <Typography variant="h6" fontFamily="Poppins">Experience Details</Typography>
                {formData.work_experiences?.map((exp, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    {editMode.group3 ? (
                      <>
                        <TextField
                          fullWidth
                          label="Company Name"
                          value={exp.company_name}
                          onChange={(e) => handleExperienceChange(index, 'company_name', e.target.value)}
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          fullWidth
                          label="Work Description"
                          value={exp.work_description}
                          onChange={(e) => handleExperienceChange(index, 'work_description', e.target.value)}
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          margin="dense"
                          name="date_started"
                          label="Date Started"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={exp.date_started}
                          onChange={(e) => handleExperienceChange(index, 'date_started', e.target.value)}
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          margin="dense"
                          name="date_ended"
                          label="Date Ended"
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          value={exp.date_ended}
                          onChange={(e) => handleExperienceChange(index, 'date_ended', e.target.value === '' ? null : e.target.value)}
                          disabled={exp.currently_working}
                          sx={{ mb: 2 }}
                        />
                        <FormControl fullWidth margin="dense">
                          <InputLabel>Industry Expertise</InputLabel>
                          <Select
                            multiple
                            value={exp.industry_expertise}
                            onChange={(event) => handleExperienceChange(index, 'industry_expertise', event.target.value)}
                            renderValue={(selected) => (
                              <div>
                                {selected.map((value) => (
                                  <Chip key={value.name} label={value.name} />
                                ))}
                              </div>
                            )}
                          >
                            {expertiseOptions?.map((expOption) => (
                              <MenuItem key={expOption.name} value={expOption}>
                                <Checkbox checked={exp.industry_expertise.some((expertise) => expertise.name === expOption.name)} />
                                <ListItemText primary={expOption.name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={exp.currently_working}
                              onChange={(e) => handleExperienceChange(index, 'currently_working', e.target.checked)}
                            />
                          }
                          label="Currently Working"
                        />
                        <IconButton onClick={() => handleDeleteExperience(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Typography variant="subtitle1">{exp.company_name}</Typography>
                        <Typography>{exp.work_description}</Typography>
                        <Typography>{exp.date_started} - {exp.date_ended || 'Present'}</Typography>
                        <Typography>{exp.currently_working ? 'Currently Working' : 'Not Working'}</Typography>
                        {exp.industry_expertise?.map((expertise, idx) => (
                          <Chip key={idx} label={expertise.name} sx={{ mr: 1, mb: 1 }} />
                        ))}
                      </>
                    )}
                  </Box>
                ))}
                {editMode.group3 && (
                  <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)} sx={{ mt: 2 }}>
                    Add Experience
                  </Button>
                )}
                {editMode.group3 && (
                  <>
                    <Button variant="contained" color="primary" onClick={() => handleSave('group3')} sx={{ mr: 1 }}>
                      Save
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => setEditMode({ ...editMode, group3: false })}>
                      Cancel
                    </Button>
                  </>
                )}
                {!editMode.group3 && (
                  <Button variant="contained" color="primary" onClick={() => setEditMode({ ...editMode, group3: true })} sx={{ mt: 2 }}>
                    Edit
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ borderRadius: 2, mb: 2 }}>
              <CardContent>
                <Typography variant="h6" fontFamily="Poppins">LinkedIn and Time Blocks</Typography>
                {editMode.group4 ? (
                  <>
                    <TextField
                      fullWidth
                      name="linkedin"
                      label="LinkedIn"
                      value={formData.linkedin}
                      onChange={handleInputChange}
                      sx={{ mb: 2 }}
                    />
                    {formData.mentor_time_blocks.map((block, index) => (
                      <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                        <Grid item xs={4}>
                          <FormControl fullWidth>
                            <InputLabel>Duration</InputLabel>
                            <Select
                              value={block.time_block_id}
                              onChange={(e) => handleTimeBlockChange(index, 'time_block_id', e.target.value)}
                            >
                              {timeBlockOptions.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.duration} minutes
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            label="Price (INR)"
                            value={block.price}
                            onChange={(e) => handleTimeBlockChange(index, 'price', e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleDeleteTimeBlock(index)}
                            startIcon={<DeleteIcon />}
                            sx={{ mt: 1 }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleAddTimeBlock}
                      sx={{ mb: 2 }}
                    >
                      Add New Time Block
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => handleSave('group4')} sx={{ mr: 1 }}>
                      Save
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => setEditMode({ ...editMode, group4: false })}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography>LinkedIn: {mentor.linkedin}</Typography>
                    <Typography>Time Blocks:</Typography>
                    <Box>
                      {mentor.mentor_time_blocks?.map((block, index) => (
                        <Chip key={index} label={`${block.time_block.duration} minutes - ${block.price} INR`} sx={{ mr: 1, mb: 1 }} />
                      ))}
                    </Box>
                    <Button variant="contained" color="primary" onClick={() => setEditMode({ ...editMode, group4: true })} sx={{ mt: 2 }}>
                      Edit
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Card>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Experience</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Company Name"
            value={newExperience.company_name}
            onChange={(e) => handleNewExperienceChange('company_name', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Work Description"
            value={newExperience.work_description}
            onChange={(e) => handleNewExperienceChange('work_description', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            name="date_started"
            label="Date Started"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={newExperience.date_started}
            onChange={(e) => handleNewExperienceChange('date_started', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            name="date_ended"
            label="Date Ended"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={newExperience.date_ended}
            onChange={(e) => handleNewExperienceChange('date_ended', e.target.value === '' ? null : e.target.value)}
            disabled={newExperience.currently_working}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Industry Expertise</InputLabel>
            <Select
              multiple
              value={newExperience.industry_expertise}
              onChange={(event) => {
                setNewExperience({ ...newExperience, industry_expertise: event.target.value });
              }}
              renderValue={(selected) => (
                <div>
                  {selected.map((value, index) => (
                    <Chip key={index} label={value.name} />
                  ))}
                </div>
              )}
            >
              {expertiseOptions?.map((exp) => (
                <MenuItem key={exp.name} value={exp}>
                  <Checkbox checked={newExperience.industry_expertise.includes(exp)} />
                  <ListItemText primary={exp.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddExperience} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MentorProfile;
