import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useLocation } from 'react-router-dom'; // Removed useParams since slug is not used
import axios from 'axios';
import LightNavBar from '../components/LightNavBar';
import EventNetworkCTA from '../components/EventNetworkCTA';

const EventDetails = () => {
  const location = useLocation(); // Get the state passed with navigate (contains event id)
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        // Use the id from location.state to fetch event details
        const response = await axios.get(`http://52.90.169.75:8000/api/events/${location.state.id}/`);
        setEvent(response.data);
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };

    if (location.state?.id) {
      fetchEvent();
    }
  }, [location.state?.id]);

  if (!event) {
    return <Typography>Loading event details...</Typography>;
  }

  return (
    <Box sx={{ padding: '50px 0', backgroundColor: '#f8f9fa' }}>
      {/* Header Section */}
      <LightNavBar />
      <Box
        sx={{
          maxWidth: '1200px',
          margin: '0 auto',
          px: 4,
          zIndex: 2,
          position: 'relative',
          textAlign: 'center',
          color: '#000000',
          padding: '60px 0',
        }}
        py={5}
      >
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          {event.title}
        </Typography>
        <Typography variant="h6" sx={{ margin: '20px 0' }}>
          {new Date(event.date).toLocaleDateString()} • {new Date(event.start_time).toLocaleTimeString()} -{' '}
          {new Date(event.end_time).toLocaleTimeString()} • {event.location}
        </Typography>
        <img
          src={event.background_image}
          alt="Topics Graph"
          style={{ maxWidth: '100%', borderRadius: '12px' }}
        />
      </Box>

      {/* About the Event Section */}
      <Box
        sx={{
          maxWidth: '1000px',
          margin: '0 auto',
          px: 4,
          zIndex: 2,
          position: 'relative',
          padding: '40px 20px',
          textAlign: 'left',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
          About this event
        </Typography>
        <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
          {event.description}
        </Typography>
      </Box>

      <Divider sx={{ my: 4 }} />

      {/* Event Highlights Section */}
      <Box
        sx={{
          maxWidth: '1000px',
          margin: '0 auto',
          px: 4,
          zIndex: 2,
          position: 'relative',
          padding: '20px',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          What happens at Menttalk Meetups?
        </Typography>
        <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
          - Authentic Networking Experience: Dive into genuine, old-school networking where meaningful connections are
          the main agenda.<br />
          - New Customer Connections: Discover opportunities to meet future clients and grow your business right at the
          meetup.<br />
          - Career Advancements: It’s not just talk – full-time job opportunities have been known to emerge, propelling
          careers to the next level.
        </Typography>
      </Box>

      {/* Statistics or Topics Graph Section */}
      <Box
        sx={{
          maxWidth: '1000px',
          margin: '0 auto',
          px: 4,
          zIndex: 2,
          position: 'relative',
          padding: '40px 20px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3 }}>
          Curious about what topics are generally discussed at our events?
        </Typography>
        <img src={event.photo} alt="Topics Graph" style={{ maxWidth: '100%', borderRadius: '12px' }} />
      </Box>
        <EventNetworkCTA/>
      <Divider sx={{ my: 4 }} />
    </Box>
  );
};

export default EventDetails;
