import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Avatar,
  Divider,
  CircularProgress,
  Box,
  Modal,
} from '@mui/material';
import axios from 'axios';
import MenteeProfileCard from '../components/MenteeProfileCard'; // Import the MenteeProfileCard component

const MentorBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMenteeId, setSelectedMenteeId] = useState(null); // To store the selected mentee's ID
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state

  // Fetch bookings for the logged-in mentor
  const fetchBookings = async () => {
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
    try {
      const response = await axios.get('http://52.90.169.75:8000/api/mentor/bookings/', {
        headers: { Authorization: `Token ${token}` },
      });
      setBookings(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching mentor bookings:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  // Function to open modal and show mentee profile
  const handleBookingClick = (menteeId) => {
    setSelectedMenteeId(menteeId); // Set the selected mentee's ID
    setIsModalOpen(true); // Open the modal
  };

  // Function to close the modal
  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedMenteeId(null); // Clear the selected mentee data
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (bookings.length === 0) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h5" sx={{ color: '#fb2f60', fontWeight: 'bold' }}>
          No Bookings Found
        </Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 4, fontWeight: 'bold', color: '#fb2f60' }}>
        Mentor Bookings
      </Typography>
      <List>
        {bookings.map((booking) => (
          <Paper
            key={booking.id}
            elevation={3}
            sx={{ padding: 2, mb: 3, backgroundColor: '#f9f9f9', cursor: 'pointer' }}
            onClick={() => handleBookingClick(booking.mentee_id)} // Handle click to open modal
          >
            <ListItem alignItems="flex-start">
              <Avatar
                alt={`${booking.mentee_first_name} ${booking.mentee_last_name}`}
                src={booking.mentee_profile_picture || '/default-avatar.jpg'}
                sx={{ width: 56, height: 56, marginRight: 2 }}
              />
              <ListItemText
                primary={
                  <>
                    <Typography variant="h6" sx={{ color: '#fb2f60', fontWeight: 'bold' }}>
                      {`${booking.mentee_first_name} ${booking.mentee_last_name}`}
                    </Typography>
                  </>
                }
                secondary={
                  <>
                    <Typography variant="body1" sx={{ color: '#333', mt: 1 }}>
                      <strong>Booking Date:</strong> {booking.booking_date}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#333' }}>
                      <strong>Booking Time:</strong> {booking.booking_start_time} - {booking.booking_end_time}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#333' }}>
                      <strong>Payment Status:</strong> {booking.payment_status ? 'Paid' : 'Pending'}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <Divider sx={{ my: 2 }} />
          </Paper>
        ))}
      </List>

      {/* Modal to display mentee profile */}
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Box>
          {selectedMenteeId && 
          (
            <MenteeProfileCard
              menteeId={selectedMenteeId} 
              // Pass the selected mentee's ID to the card
              onClose={handleModalClose}
            />
            
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default MentorBookings;
