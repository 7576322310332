import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Card } from '@mui/material';
import Slider from "react-slick";
import axios from 'axios';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/home/1/');
        setReviews(response.data.reviews);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    fetchReviews();
  }, []);

  if (reviews.length === 0) {
    return <Typography>Loading reviews...</Typography>;
  }

  const sliderSettingsRight = {
    infinite: true,
    speed: 5000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    pauseOnHover: false,
    arrows: false,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
  };

  const sliderSettingsLeft = {
    ...sliderSettingsRight,
    rtl: true, // Slides to the left
  };

  return (
    <Box
      sx={{
        width: '100%',
        py: 4,
        px: 2,
        backgroundColor: '#580017', // Dark background
        textAlign: 'center',
        overflow: 'hidden',
        position: 'relative',
        color: '#fff',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ mb: 4, fontWeight: 'bold', color: '#fff' }}>
        What Our Users Say
      </Typography>

      {/* First Row (Sliding to the Right) */}
      <Slider {...sliderSettingsRight}>
        {reviews.map((review, index) => (
          <Card
            key={index}
            sx={{
              p: 2,
              mx: 5, // Adds space between cards horizontally
              backgroundColor: '#fff',
              color: '#333',
              borderRadius: '10px',
              textAlign: 'left',
              width: '250px', // Less wide cards
              boxShadow: '20', // Remove card shadow
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar
                src={review.reviewer_profile_picture} // Assuming reviewer has a profile picture field
                alt={review.reviewer}
                sx={{
                  width: 40,
                  height: 40,
                  mr: 2,
                }}
              />
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                  {review.reviewer}
                </Typography>
                <Typography variant="body2" sx={{ color: '#888' }}>
                  Mentee {/* Dynamic role if available */}
                </Typography>
              </Box>
            </Box>
            <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#555', mb: 1 }}>
              {`"${review.content}"`}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              {[...Array(review.rating)].map((_, i) => (
                <Typography
                  key={i}
                  variant="body2"
                  sx={{ color: '#FFD700', fontSize: '18px', mr: 0.3 }}
                >
                  ★
                </Typography>
              ))}
            </Box>
          </Card>
        ))}
      </Slider>

      {/* Second Row (Sliding to the Left) */}
      <Slider {...sliderSettingsLeft} sx={{ mt: 4 }}>
        {reviews.map((review, index) => (
          <Card
            key={index}
            sx={{
              p: 2,
              mx: 2, // Adds space between cards horizontally
              backgroundColor: '#fff',
              color: '#333',
              borderRadius: '10px',
              textAlign: 'left',
              width: '250px', // Less wide cards
              boxShadow: '20', // Remove card shadow
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar
                src={review.reviewer_profile_picture} 
                alt={review.reviewer}
                sx={{
                  width: 40,
                  height: 40,
                  mr: 2,
                }}
              />
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                  {review.reviewer}
                </Typography>
                <Typography variant="body2" sx={{ color: '#888' }}>
                  Mentee
                </Typography>
              </Box>
            </Box>
            <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#555', mb: 1 }}>
              {`"${review.content}"`}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              {[...Array(review.rating)].map((_, i) => (
                <Typography
                  key={i}
                  variant="body2"
                  sx={{ color: '#FFD700', fontSize: '18px', mr: 0.3 }}
                >
                  ★
                </Typography>
              ))}
            </Box>
          </Card>
        ))}
      </Slider>
    </Box>
  );
};

export default Reviews;
