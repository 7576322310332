import axios from 'axios';

const API_BASE_URL = 'http://52.90.169.75:8000/api';

const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Token ${token}` } : {};
};

export { getAuthHeaders };

// Google signup and login
export const googleSignup = async (token) => {
  const response = await axios.post(`${API_BASE_URL}/google-signup/`, { token });
  return response.data;
};

export const loginUser = async (credentials) => {
  const response = await axios.post(`${API_BASE_URL}/auth/login/`, credentials);
  localStorage.setItem('token', response.data.token); // Save token on login
  return response.data;
};

// Mentor and Profile-related endpoints
export const fetchLoggedInMentorProfile = async () => {
  const response = await axios.get(`${API_BASE_URL}/mentors/me/`, { headers: getAuthHeaders() });
  return response.data;
};

export const updateMentorProfile = async (data) => {
  console.log('Data being sent to update mentor profile:', data); // Log the data
  const response = await axios.put(`${API_BASE_URL}/mentors/me/`, data, { headers: getAuthHeaders() });
  return response.data;
};

export const fetchMentor = async (mentorId) => {
  const response = await axios.get(`${API_BASE_URL}/mentors/${mentorId}/`, { headers: getAuthHeaders() });
  return response.data;
};

export const fetchMentors = async () => {
  const response = await axios.get(`${API_BASE_URL}/mentors/`, { headers: getAuthHeaders() });
  return response.data;
};

export const convertToMentor = async () => {
  const response = await axios.post(`${API_BASE_URL}/convert-to-mentor/`, {}, { headers: getAuthHeaders() });
  return response.data;
};

// Time Blocks and Booking-related endpoints
export const fetchTimeBlockOptions = async () => {
  const response = await axios.get(`${API_BASE_URL}/time-blocks/`, { headers: getAuthHeaders() });
  return response.data;
};

export const fetchTimeBlocks = async (mentorId) => {
  const response = await axios.get(`${API_BASE_URL}/time-blocks/?mentor=${mentorId}`, { headers: getAuthHeaders() });
  return response.data;
};

export const bookMentor = async (mentorId, timeBlockId, availabilityId) => {
  const response = await axios.post(`${API_BASE_URL}/booking/bookings/`, {  mentorId, timeBlockId, availabilityId }, { headers: getAuthHeaders() });
  console.log('Create Booking Data:', response.data);
  return response.data;
};




export const updateBookingStatus = async (bookingId, status) => {
  const response = await axios.post(`${API_BASE_URL}/booking/status/${bookingId}/${status}/`, {}, { headers: getAuthHeaders() });
  console.log(`Booking ${status} Data:`, response.data);
  return response.data;
};

export const fetchBookings = async () => {
  const response = await axios.get(`${API_BASE_URL}/booking/`, { headers: getAuthHeaders() });
  console.log('API Response:', response.data);
  return response.data;
};

// Expertise and Tools options
export const fetchExpertiseOptions = async () => {
  const response = await axios.get(`${API_BASE_URL}/expertise/`, { headers: getAuthHeaders() });
  return response.data;
};

export const fetchToolOptions = async () => {
  const response = await axios.get(`${API_BASE_URL}/tools/`, { headers: getAuthHeaders() });
  return response.data;
};

// Event-related endpoints
export const fetchEvents = async () => {
  const response = await axios.get(`${API_BASE_URL}/events/`, { headers: getAuthHeaders() });
  return response.data;
};

export const fetchEvent = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/events/${id}/`, { headers: getAuthHeaders() });
  return response.data;
};

export const registerForEvent = async (payload) => {
  const response = await axios.post(`${API_BASE_URL}/event-registrations/`, payload, { headers: getAuthHeaders() });
  return response.data;
};

export const updateEventPaymentStatus = async (registrationId, paymentId) => {
  const response = await axios.patch(`${API_BASE_URL}/event-payments/${registrationId}/`, {
    razorpay_payment_id: paymentId,
    status: 'Success'
  }, { headers: getAuthHeaders() });

  return response.data;
};



// Other endpoints
export const fetchHomePage = async () => {
  const response = await axios.get(`${API_BASE_URL}/webadmin/home/1/`);
  return response.data;
};

export const fetchAboutPage = async () => {
  const response = await axios.get(`${API_BASE_URL}/webadmin/about/1/`);
  return response.data;
};

export const fetchContactPage = async () => {
  const response = await axios.get(`${API_BASE_URL}/webadmin/contact/1/`);
  return response.data;
};

export const fetchPrivacyPolicyPage = async () => {
  const response = await axios.get(`${API_BASE_URL}/webadmin/privacy/1/`);
  return response.data;
};

export const fetchNewsletterPage = async () => {
  const response = await axios.get(`${API_BASE_URL}/webadmin/newsletter/1/`);
  return response.data;
};

export const fetchCustomPages = async () => {
  const response = await axios.get(`${API_BASE_URL}/webadmin/custom-pages/`);
  return response.data;
};

export const fetchBlogs = async () => {
  const response = await axios.get(`${API_BASE_URL}/blogs/`);
  return response.data;
};

export const fetchBlog = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/blogs/${id}/`);
  return response.data;
};

export const fetchMentorProfile = async (username) => {
  const response = await axios.get(`${API_BASE_URL}/mentors/username/${username}/`);
  return response.data;
};

export const fetchCohorts = async () => {
  const response = await axios.get(`${API_BASE_URL}/cohorts/`, { headers: getAuthHeaders() });
  return response.data;
};

export const createCohortRegistration = async (cohortId) => {
  const response = await axios.post(`${API_BASE_URL}/registrations/`, { cohort: cohortId }, { headers: getAuthHeaders() });
  return response.data;
};

export const updatePaymentStatus = async (registrationId, paymentId) => {
  const response = await axios.patch(`${API_BASE_URL}/payments/${registrationId}/`, { razorpay_payment_id: paymentId, status: 'Success' }, { headers: getAuthHeaders() });
  return response.data;
};

export const verifyPayment = async (paymentData) => {
  const response = await axios.post(`${API_BASE_URL}/payment-verification/`, paymentData, { headers: getAuthHeaders() });
  return response.data;
};

export const createEventRegistration = async (eventId) => {
  const response = await axios.post(`${API_BASE_URL}/event-registrations/`, { event: eventId }, { headers: getAuthHeaders() });
  return response.data;
};

export const verifyEventPayment = async (paymentData) => {
  const response = await axios.post(`${API_BASE_URL}/event-payment-verification/`, paymentData, { headers: getAuthHeaders() });
  return response.data;
};










// Payment Functions
export const updateBookingPaymentStatus = async (bookingId, paymentId) => {
  const response = await axios.patch(`${API_BASE_URL}/booking-payments/${bookingId}/`, { razorpay_payment_id: paymentId, status: 'Success' }, { headers: getAuthHeaders() });
  return response.data;
};

export const verifyBookingPayment = async (paymentData) => {
  const response = await axios.post(`${API_BASE_URL}/verify-booking-payment/`, paymentData, { headers: getAuthHeaders() });
  return response.data;
};

export const fetchAvailableSlots = async (mentorId) => {
  const response = await axios.get(`${API_BASE_URL}/mentors/${mentorId}/available-slots/`, { headers: getAuthHeaders() });
  return response.data;
};

// Create a booking for the selected mentor, availability, and time slot
export const createBooking = async (bookingData) => {
  const response = await axios.post(`${API_BASE_URL}/mentorbooking/create-booking/`, bookingData, { headers: getAuthHeaders() });
  return response.data;
};

// Verify the payment after Razorpay completes the payment
export const verifyBookinPayment = async (paymentData) => {
  const response = await axios.post(`${API_BASE_URL}/mentorbooking/verify-payment/`, paymentData, { headers: getAuthHeaders() });
  return response.data;
};

export const fetchMenteeBookings = async () => {
    const response = await axios.get(`${API_BASE_URL}/mentee-bookings/`, { headers: getAuthHeaders()}); // Include token in the Authorization header
    return response.data;  // Return the bookings data from the API response
  } ;

  export const fetchMenteeRegisteredEvents = async () => {
    const response = await axios.get(`${API_BASE_URL}/mentee/events/`, { headers: getAuthHeaders()});
    return response.data;
  };

export const fetchMenteeProfile = async () => {const response = await axios.get(`${API_BASE_URL}/mentee-profile/`, { headers: getAuthHeaders()}); // Include token in the Authorization header
return response.data;  // Return the bookings data from the API response
} ;

export const updateMenteeProfile = async (profileData) => {
  const response = await axios.patch(`${API_BASE_URL}/mentee-profile/`, profileData, { 
    headers: getAuthHeaders(),
  });
  return response.data;
};

export const fetchMentorBookings = async () => { // Assuming you are storing token in localStorage
  const response = await axios.get(`${API_BASE_URL}/mentor/bookings/`, {
    headers: getAuthHeaders(),
  });
  return response.data;
};