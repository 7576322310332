import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../components/NavBar';
import AboutIntro from '../components/AboutIntro';
import AboutStory from '../components/AboutStory';
import Team from '../components/Team';
import AboutCoreValues from '../components/AboutCoreValues';
import BoardOfAdvisors from '../components/BoardOfAdvisors';
import AboutCTA from '../components/AboutCTA';
import BlogsCTA from '../components/BlogCTA';
import Footer from '../components/Footer';

const AboutPage = () => {
    const [aboutData, setAboutData] = useState(null);

    useEffect(() => {
        // Fetch the About Page data from API
        axios.get('http://52.90.169.75:8000/api/webadmin/about/1')
            .then(response => {
                setAboutData(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the about page data:", error);
            });
    }, []);

    if (!aboutData) {
        return <div>Loading...</div>;
    }

    // Inline CSS Styles
    const styles = {
        aboutPage: {
            fontFamily: 'Arial, sans-serif',
            color: '#333'
        },
        heroSection: {
            backgroundColor: '#f5f5f5',
            padding: '50px',
            textAlign: 'center'
        },
        heroContent: {
            marginBottom: '30px'
        },
        heroTitle: {
            fontSize: '3em',
            marginBottom: '20px'
        },
        heroDescription: {
            fontSize: '1.2em',
            marginBottom: '30px'
        },
        introImage: {
            width: '100%',
            maxWidth: '600px',
            margin: '20px auto'
        },
        subIntroImages: {
            display: 'flex',
            justifyContent: 'space-around',
            margin: '30px 0'
        },
        subIntroImage: {
            width: '45%',
            maxWidth: '400px'
        },
        storySection: {
            padding: '50px',
            backgroundColor: '#fff',
            textAlign: 'center'
        },
        storyTitle: {
            fontSize: '2.5em',
            marginBottom: '20px'
        },
        coreValuesSection: {
            backgroundColor: '#f5f5f5',
            padding: '50px',
            textAlign: 'center'
        },
        coreValuesList: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around'
        },
        coreValueCard: {
            width: '30%',
            marginBottom: '20px',
            padding: '20px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
        },
        advisorsSection: {
            padding: '50px',
            textAlign: 'center',
            backgroundColor: '#fff'
        },
        advisorsGrid: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around'
        },
        advisorCard: {
            width: '30%',
            textAlign: 'center',
            marginBottom: '30px'
        },
        advisorImage: {
            width: '100px',
            height: '100px',
            borderRadius: '50%'
        },
        ctaSection: {
            backgroundColor: '#6D28F1',
            color: 'white',
            textAlign: 'center',
            padding: '50px'
        },
        ctaButton: {
            backgroundColor: '#fff',
            color: '#6D28F1',
            padding: '10px 20px',
            borderRadius: '20px',
            margin: '10px',
            textDecoration: 'none'
        },
        blogsSection: {
            padding: '50px',
            backgroundColor: '#f5f5f5',
            textAlign: 'center'
        },
        blogsGrid: {
            display: 'flex',
            justifyContent: 'space-around'
        },
        blogCard: {
            width: '30%',
            marginBottom: '20px'
        },
        blogImage: {
            width: '100%',
            height: '200px',
            objectFit: 'cover'
        },
        blogLink: {
            color: '#6D28F1',
            textDecoration: 'none',
            fontWeight: 'bold'
        }
    };

    return (
      
        <div style={styles.aboutPage}>
            {/* Hero Section */}
            <Navbar/>
            <AboutIntro/>
            <AboutStory/>
            <Team/>
            <AboutCoreValues/>
            <BoardOfAdvisors/>
            <AboutCTA/>
            <BlogsCTA/>
            <Footer/>
        </div>
    );
};

export default AboutPage;
