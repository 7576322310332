import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import axios from 'axios';

const AboutCTA = () => {
  const [ctaData, setCtaData] = useState(null);

  useEffect(() => {
    const fetchCTAData = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/about/1'); // Replace with correct API URL
        setCtaData(response.data.cta_card);
      } catch (error) {
        console.error('Error fetching CTA data:', error);
      }
    };
    fetchCTAData();
  }, []);

  if (!ctaData) {
    return <div>Loading...</div>;
  }

  const { title, description, buttons } = ctaData;

  return (
    <Box
      sx={{
        backgroundColor: '#c0332b', // Background color behind the CTA card
        py: 6, // Padding for top and bottom to make space around the CTA card
        display: 'flex',
        justifyContent: 'center', // Center the CTA card
      }}
    >
      <Box
        sx={{
          background: 'linear-gradient(180deg, #fed7db 0%, #fe7544 100%)', // Gradient background
          borderRadius: '16px',
          padding: '50px',
          textAlign: 'center',
          maxWidth: '1800px',
          margin: '0 auto', // Ensures the CTA card is centered
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)', // Soft shadow for a floating effect
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Grid container alignItems="center">
          {/* Text Section */}
          <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333', mb: 2 }}>
              {title}
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', mb: 4 }}>
              {description}
            </Typography>
          </Grid>

          {/* Button Section */}
          <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
            {buttons.map((button, index) => (
              <Button
                key={index}
                href={button.url}
                variant="contained"
                sx={{
                  backgroundColor: '#c0332b',
                  color: '#fff',
                  padding: '10px 20px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '16px',
                  '&:hover': {
                    backgroundColor: '#4A148C',
                  },
                }}
              >
                {button.text}
              </Button>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AboutCTA;
