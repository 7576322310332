import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Card, CardContent, CardMedia, Grid, Avatar,   Skeleton } from '@mui/material';
import Slider from "react-slick";
import { fetchHomePage } from '../services/api';

import Navbar from '../components/NavBar';
import MentorSearch from '../components/MentorSearch';

import HomeIntro from '../components/HomeIntro';
import FAQs from '../components/FAQs';
import Footer from '../components/Footer';

// Import CSS files for react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MentoringCTA from '../components/MentoringCTA';
import MenttalkCTA from '../components/MenttalkCTA';
import OurMentors from '../components/OurMentors';
import MentorshipCategories from '../components/MentorshipCategories';
import EventsComing from '../components/EventsComing';
import Reviews from '../components/Reviews';

// Colors for the theme
const themeColors = {
  primary: '#590119',
  accent: '#ec2d5b',
  background: '#FAFAFA',
  lightAccent: '#FFD1DA',
};

// Function to select a random light color for card backgrounds
const getRandomLightColor = () => {
  const colors = ['#FFF7F1', '#D2E0FB', '#F9F3CC', '#D7E5CA', '#FFFBF5', '#F7EFE5', '#CDFADB', '#FFD1DA', '#FFF0F5', '#FFDBAA', '#D8EFD3', '#F1F8E8', '#CAF4FF'];
  return colors[Math.floor(Math.random() * colors.length)];
};



const Homepage = () => {
  const [homepageData, setHomepageData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchHomePage();
        setHomepageData(data);
        console.log(data);
      } catch (error) {
        console.error('Error fetching homepage data:', error);
      }
    };
    fetchData();
  }, []);

  if (!homepageData) {
    return (
      <Container>
        <Skeleton variant="rectangular" height={500} />
        <Skeleton variant="text" height={40} />
        <Skeleton variant="text" height={40} />
      </Container>
    );
  }

  // Mentor slider settings
  

  // Category slider settings
  const categorySliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 1 } }
    ],
    centerMode: true,
    centerPadding: '20px',
    swipeToSlide: true,
  };

  

  return (
    <>
      {/* Header and Navbar */}
      
      <Navbar
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          backgroundColor: themeColors.primary,
        }}
      />

      {/* Mentor Search and Google Login */}
      <Box sx={{
          width: '100%',
          height: '80vh',
          backgroundImage: 'linear-gradient(150deg, #590119, #ec2d5b)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          color: 'white',
          textAlign: 'center',
         }}>
        <Container maxWidth="80%">
          <MentorSearch />
          
        </Container>
      </Box>
         {/* HomeIntro Component */}
      <HomeIntro />
      <MenttalkCTA />
      <EventsComing/>
      <OurMentors/>
      {/* Mentor Slider Section */}
      
      <MentoringCTA />
      {/* Company Logos Section */}
      <Box sx={{ width: '100%', backgroundColor: themeColors.background, py: 4 }}>
        <Container>
          <Grid container spacing={2} justifyContent="center">
            {homepageData.company_logos.map((logo) => (
              <Grid item key={logo.name}>
                <Avatar src={logo.logo} alt={logo.name} sx={{ width: 100, height: 100 }} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      
      <MentorshipCategories/>
      {/* Mentorship Categories Slider */}
      <Container>
        <Typography variant="h4" gutterBottom>
          Mentorship Categories
        </Typography>
        <Slider {...categorySliderSettings}>
          {homepageData.categories.map((category) => (
            <Box key={category.name} sx={{ padding: 2 }}>
              <Card sx={{ borderRadius: '16px', backgroundColor: getRandomLightColor(), minWidth: 240, maxWidth: 240 }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={category.icon || "/default-category.png"}
                  alt={category.name}
                  sx={{ borderRadius: '16px 16px 0 0' }}
                />
                <CardContent>
                  <Typography variant="h5" component="div">{category.name}</Typography>
                  <Typography variant="body2" color="textSecondary">{category.description}</Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Slider>
      </Container>

    

      <Reviews/>

      {/* FAQs Section */}
      <FAQs />
      <Footer/>

    </>
  );
};

export default Homepage;
