import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, Menu, MenuItem as MuiMenuItem, Avatar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from 'axios';

const LightNavBar = () => {
  const [menus, setMenus] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [logos, setLogos] = useState({ normal_logo: '', sticky_logo: '' });
  const [loggedIn, setLoggedIn] = useState(false); // Check if user is logged in
  const [userProfile, setUserProfile] = useState({}); // Store user profile info

  const navigate = useNavigate();

  // Fetch menu items and logos from backend
  const fetchMenuData = async () => {
    try {
      const response = await axios.get('http://52.90.169.75:8000/navigation/api/menus/');
      setMenus(response.data.menu);
      setLogos(response.data.logos);
    } catch (error) {
      console.error('Error fetching menu data', error);
    }
  };

  // Fetch user profile data
  const fetchUserProfile = async () => {
    const token = localStorage.getItem('token'); // Check for auth token in localStorage
    if (token) {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/user-profile/', {
          headers: { Authorization: `Token ${token}` },
        });
        setUserProfile(response.data);
        setLoggedIn(true);
      } catch (error) {
        console.error('Error fetching user profile', error);
      }
    }
  };

  useEffect(() => {
    fetchMenuData();
    fetchUserProfile();
  }, []);

  // Handle scroll event to change the logo when scrolled
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Open submenu
  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenSubMenu(index);
  };

  // Close submenu
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenSubMenu(null);
  };

  // Open profile menu
  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchor(event.currentTarget);
  };

  // Close profile menu
  const handleProfileMenuClose = () => {
    setProfileMenuAnchor(null);
  };

  // Log out function
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token
    setLoggedIn(false); // Update state
    navigate('/login'); // Redirect to login
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: scrolled ? '#ffffff' : 'transparent',
        boxShadow: scrolled ? 2 : 'none',
        transition: 'background-color 0.3s ease',
        padding: '0 20px',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Left side: Logo and Menu Links */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* Logo */}
          <Link to="/" style={{ marginRight: '20px' }}>
            <img
              src={scrolled ? logos.sticky_logo : logos.normal_logo}
              alt="Logo"
              style={{ height: '40px', objectFit: 'contain' }}
            />
          </Link>

          {/* Menu Links (aligned to the left) */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {menus.map((menu, index) => (
              <div key={menu.title} style={{ position: 'relative' }}>
                {menu.submenus.length > 0 ? (
                  <>
                    <Button
                      sx={{ 
                        color: scrolled ? '#333' : 'black', // Black text for light navbar
                        fontSize: '16px',
                        textTransform: 'none',
                        padding: '0 20px'
                      }}
                      onClick={(event) => handleMenuOpen(event, index)}
                    >
                      {menu.title}
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={openSubMenu === index}
                      onClose={handleMenuClose}
                      MenuListProps={{ onMouseLeave: handleMenuClose }}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                      {menu.submenus.map((submenu) => (
                        <MuiMenuItem
                          key={submenu.title}
                          component={Link}
                          to={submenu.url}
                          onClick={handleMenuClose}
                          sx={{ color: 'black' }} // Ensure submenu items remain black
                        >
                          {submenu.title}
                        </MuiMenuItem>
                      ))}
                    </Menu>
                  </>
                ) : (
                  <Button
                    key={menu.title}
                    component={Link}
                    to={menu.url}
                    sx={{ 
                      color: scrolled ? '#333' : 'black', // Black text for menu items
                      fontSize: '16px',
                      textTransform: 'none',
                      padding: '0 20px'
                    }}
                  >
                    {menu.title}
                  </Button>
                )}
              </div>
            ))}
          </Box>
        </Box>

        {/* Right side: Buttons or Profile */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            component={Link}
            to="/explore-membership"
            sx={{ 
              color: 'black', // Black text for light background
              fontSize: '16px',
              textTransform: 'none',
              marginRight: '20px'
            }}
          >
            Explore Membership
          </Button>

          {/* Conditional rendering based on whether the user is logged in */}
          {loggedIn ? (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleProfileMenuOpen}>
                <Avatar
                  src={userProfile.profile_picture}
                  alt={userProfile.username}
                  sx={{ marginRight: '10px' }}
                />
                <ArrowDropDownIcon sx={{ color: 'black' }} />
              </Box>
              <Menu
                anchorEl={profileMenuAnchor}
                open={Boolean(profileMenuAnchor)}
                onClose={handleProfileMenuClose}
              >
                <MuiMenuItem component={Link} to="/dashboard">
                  Dashboard
                </MuiMenuItem>
                <MuiMenuItem component={Link} to="/profile">
                  My Profile
                </MuiMenuItem>
                <MuiMenuItem onClick={handleLogout}>Logout</MuiMenuItem>
              </Menu>
            </>
          ) : (
            <Button
              component={Link}
              to="/login"
              sx={{ 
                color: 'black', // Black text for light background
                fontSize: '16px',
                textTransform: 'none',
                marginRight: '20px'
              }}
            >
              Login
            </Button>
          )}

          <Button
            component={Link}
            to="/browse-mentors"
            sx={{
              backgroundColor: '#fb2f60',
              color: 'white',
              padding: '8px 20px',
              borderRadius: '30px',
              fontSize: '16px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#d02550'
              }
            }}
          >
            Browse mentors
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default LightNavBar;
