// src/pages/BlogList.js
import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, CardMedia, Typography, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { fetchBlogs } from '../services/api';
import Head from '../components/Head';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchBlogs();
        setBlogs(data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Head>
      <Container>
        <Typography variant="h4" gutterBottom>
          Blog Posts
        </Typography>
        <Grid container spacing={4}>
          {blogs.map((blog) => (
            <Grid item xs={12} sm={6} md={4} key={blog.id}>
              <Card>
                <CardMedia
                  component="img"
                  alt={blog.title}
                  height="140"
                  image={blog.thumbnail || "/default-thumbnail.jpg"}
                />
                <CardContent>
                  <Typography variant="h5" component="div">
                    <Link to={`/blogs/${blog.id}`}>{blog.title}</Link>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {blog.seo_description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Head>
  );
};

export default BlogList;
