import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'; 
import Layout from './components/Layout';
import MentorDashboard from './pages/MentorDashboard';
import MentorProfile from './pages/MentorProfile';
import MentorBookings from './pages/MentorBookings';
import MentorAvailabilities from './pages/MentorAvailabilities';
import Login from './pages/Login';
import MenteeDashboard from './pages/MenteeDashboard';
import BookMentor from './components/BookMentor';
import EventRegistration from './components/EventRegistration';
import Homepage from './pages/Homepage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import NewsletterPage from './pages/NewsLetterPage';
import BlogList from './components/BlogList';
import BlogDetail from './components/BlogDetail';
import RegistrationForm from './components/RegistrationForm';
import OTPVerification from './components/OTPVerification';
import Cohorts from './pages/Cohorts';  // Import Cohorts component
import Events from './pages/Events';
import BookingPage from './pages/BookingPage';
import ExploreMentors from './pages/ExploreMentors';
import EventDetails from './pages/EventDetails';
import BrowseMentor from './pages/BrowseMentor';
import MentorDetails from './pages/MentorDetails'; 
import MentorProfiling from './pages/MentorProfiling';
import MentorBooking from './pages/MentorBooking';
import MenteeBookings from './components/MenteeBookings';
import MenteeEvents from './components/MenteeEvents';
import MenteeProfile from './components/MenteeProfile';

const App = () => {
  const [userType, setUserType] = useState(localStorage.getItem('user_type'));

  return (
    <GoogleOAuthProvider clientId="97262453299-dnu5p4m5se2e5ajq1jkti83u21p7ugo6.apps.googleusercontent.com">
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/newsletter" element={<NewsletterPage />} />
          <Route path="/login" element={<Login setUserType={setUserType} />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/verify-otp" element={<OTPVerification />} />
          <Route path="/blogs" element={<BlogList />} />
          <Route path="/blogs/:id" element={<BlogDetail />} />
          <Route path="/cohorts" element={<Cohorts />} />  {/* Add this line for cohorts route */}
          <Route path="/events" element={<Events />} />
          <Route path="/events/:slug" element={<EventDetails />} />
          <Route path="/explore-mentors" element={<ExploreMentors />} />
          <Route path="/browse-mentors" element={<BrowseMentor />} />
          <Route path="/mentors/:username" element={<MentorDetails />} /> {/* Mentor details route */}
          <Route path="/mentors/:id" element={<MentorProfiling />} />
          <Route path="/mentorbooking" element={<MentorBooking />} />

          {userType === 'mentor' ? (
            <>
              <Route path="/dashboard" element={<Layout><MentorDashboard /></Layout>} />
              <Route path="/profile" element={<Layout><MentorProfile /></Layout>} />
              <Route path="/bookings" element={<Layout><MentorBookings /></Layout>} />
              <Route path="/availabilities" element={<Layout><MentorAvailabilities /></Layout>} />
            </>
          ) : (
            <>
              <Route path="/dashboard" element={<Layout><MenteeDashboard /></Layout>} />
              <Route path="/book-mentor/:mentorId" element={<Layout><BookMentor /></Layout>} />
              <Route path="/events/register/:eventId" element={<Layout><EventRegistration /></Layout>} />
              <Route path="/cohorts" element={<Cohorts />} />  {/* Ensure mentees can access cohorts */}
              <Route path="/mentee-bookings" element={<MenteeBookings/>} />
              <Route path="/mentee-events" element={<MenteeEvents/>} />
              <Route path="/profile" element= {<MenteeProfile/>} />
              <Route path="/booking/:timeBlockId/:availabilityId" element={<Layout><BookingPage /></Layout>} /> {/* Add this line for BookingPage */}
            </>
          )}
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
