import React, { useState, useEffect, useCallback } from 'react';
import { fetchMentors, fetchAvailableSlots, createBooking, verifyBookinPayment } from '../services/api'; // Import the API functions

const MentorBooking = () => {
    const [mentors, setMentors] = useState([]);
    const [selectedMentor, setSelectedMentor] = useState(null);
    const [availabilities, setAvailabilities] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [bookingLoading, setBookingLoading] = useState(false);

    // Fetch mentors on component mount
    useEffect(() => {
        fetchMentorsList();
    }, []);

    // Fetch all mentors
    const fetchMentorsList = async () => {
        try {
            setLoading(true);
            const mentors = await fetchMentors();
            console.log("Mentors fetched: ", mentors); // Log mentors data
            setMentors(mentors);
            setLoading(false);
        } catch (err) {
            console.error("Error fetching mentors:", err); // Log any errors
            setError('Failed to load mentors');
            setLoading(false);
        }
    };

    // Fetch available slots based on selected mentor
    const fetchAvailabilities = useCallback(async () => {
        if (!selectedMentor) return;

        try {
            setLoading(true);
            const slots = await fetchAvailableSlots(selectedMentor);
            console.log("Available slots fetched: ", slots); // Log available slots
            setAvailabilities(Array.isArray(slots) ? slots : []);
            setLoading(false);
        } catch (err) {
            console.error("Error fetching availabilities:", err); // Log any errors
            setError('Failed to load availabilities');
            setLoading(false);
        }
    }, [selectedMentor]);

    useEffect(() => {
        fetchAvailabilities();
    }, [fetchAvailabilities]);

    // Handle mentor selection
    const handleMentorSelection = (e) => {
        console.log("Selected Mentor ID: ", e.target.value); // Log selected mentor ID
        setSelectedMentor(e.target.value);
        setSelectedSlot(null);
        setSelectedTimeSlot(null);
        setAvailabilities([]); // Clear previous availabilities when changing mentor
    };

    // Handle booking slot selection
    const handleSlotSelection = (availability, timeSlot, timeSlotId) => {
        console.log("Selected Availability: ", availability); // Log selected availability
        console.log("Selected Time Slot: ", timeSlot); // Log selected time slot
        console.log("Selected Time Slot ID: ", timeSlotId); // Log selected time slot ID

        setSelectedSlot(availability);
        setSelectedTimeSlot({
            ...timeSlot,
            time_slot_id: timeSlotId // Ensure the correct time_slot_id is being set
        });
    };

    // Initiate Razorpay payment and booking
    const handleBooking = async () => {
        if (!selectedSlot || !selectedTimeSlot) {
            alert("Please select a time slot to book.");
            return;
        }

        setBookingLoading(true);

        try {
            const availabilityId = selectedSlot.availability_id; // Ensure availability ID is set correctly
            const timeSlotId = selectedTimeSlot.time_slot_id; // Ensure time slot ID is set correctly

            const date = selectedSlot.date; // Get the date from the selectedSlot
            const startTime = `${date} ${selectedTimeSlot.start}`; // Combine date and start time
            const endTime = `${date} ${selectedTimeSlot.end}`; // Combine date and end time

            console.log("Availability ID:", availabilityId);  // Log the availability ID
            console.log("Time Slot ID:", timeSlotId);  // Log the time slot ID
            console.log("Start Time:", startTime);  // Log the combined start time
            console.log("End Time:", endTime);  // Log the combined end time

            // Prepare booking data
            const bookingData = {
                mentor_id: selectedMentor,
                availability_id: availabilityId,
                time_slot_id: timeSlotId,
                start_time: startTime,
                end_time: endTime,
            };

            console.log("Booking data being sent: ", bookingData); // Log booking data

            // Send booking request to backend and get Razorpay order details
            const bookingResponse = await createBooking(bookingData);

            console.log("Booking response received: ", bookingResponse); // Log booking response

            const { razorpay_order_id, razorpay_key, amount, currency, booking_id } = bookingResponse;

            // Create Razorpay payment options
            const options = {
                key: razorpay_key, // Razorpay key from backend response
                amount: amount, // Amount in paise from backend response
                currency: currency, // Currency from backend response
                name: "Mentor Booking",
                description: "Pay for your mentorship session",
                order_id: razorpay_order_id, 
                handler: async function (response) {
                    // Ensure these values are captured correctly in the response
                    console.log("Razorpay payment successful, response: ", response);
        
                    const paymentData = {
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_order_id: response.razorpay_order_id, // Ensure it's passed here
                        razorpay_signature: response.razorpay_signature,
                        booking_id: booking_id
                    };
        
                    console.log("Payment data being sent for verification: ", paymentData);
                    const verifyResponse = await verifyBookinPayment(paymentData);
        
                    if (verifyResponse.status === 'Payment successful!') {
                        alert("Booking successful! Payment verified.");
                    } else {
                        alert("Payment verification failed. Please try again.");
                    }
                },
                prefill: {
                    name: "", // Optional: autofill user data
                    email: "",
                    contact: ""
                },
                theme: {
                    color: "#F37254"
                }
            };

            // Open Razorpay payment modal
            const razorpay = new window.Razorpay(options);
            razorpay.open();
        } catch (err) {
            console.error("Error in booking or payment initiation: ", err); // Log any errors
            alert("Failed to initiate booking. Please try again.");
        } finally {
            setBookingLoading(false);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <h1>Book a session with a Mentor</h1>

            {/* Select a mentor first */}
            <div>
                <label>Select a Mentor:</label>
                <select value={selectedMentor} onChange={handleMentorSelection}>
                    <option value="">-- Choose a Mentor --</option>
                    {mentors.map((mentor) => (
                        <option key={mentor.id} value={mentor.id}>
                            {mentor.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Show available slots after selecting a mentor */}
            {selectedMentor && availabilities.length === 0 && <p>No available slots for this mentor.</p>}
            {Array.isArray(availabilities) && availabilities.map((availability, availIdx) => (
                <div key={availIdx}>
                    <h3>Date: {availability.date}</h3>
                    <p>Time: {availability.start_time} - {availability.end_time}</p>

                    {/* Loop through slot types */}
                    {availability.slots.map((slotType, index) => (
                        <div key={index}>
                            <h4>{slotType.time_slot_duration} Minute Slots (₹{slotType.price})</h4>
                            {slotType.slots.length > 0 ? (
                                slotType.slots.map((slot, idx) => (
                                    <div key={idx}>
                                        <p>
                                            Time Slot: {slot.start} - {slot.end}
                                            <button onClick={() => handleSlotSelection(availability, slot, slotType.time_slot_id)}>
                                                Select
                                            </button>
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <p>No slots available</p>
                            )}
                        </div>
                    ))}
                </div>
            ))}

            <button disabled={!selectedSlot || !selectedTimeSlot || bookingLoading} onClick={handleBooking}>
                {bookingLoading ? "Processing..." : "Book Now"}
            </button>
        </div>
    );
};

export default MentorBooking;
