import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';

const EventNetworkCTA = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(90deg, #580017 0%, #c0332b 35%, #fb2f60 100%)',
        borderRadius: '12px',
        padding: '30px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '900px',
        margin: '0 auto',
        mt: 4, // Add margin to top
        mb: 4, // Add margin to bottom
        boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)', // Shadow effect
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#fff' }}>
        Want to network with the best startup Mentors in Menttalk?
      </Typography>
      <Button
        variant="outlined"
        startIcon={<GroupIcon />}
        sx={{
          borderColor: '#fff',
          color: '#fff',
          borderRadius: '25px',
          textTransform: 'none',
          padding: '8px 20px',
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderColor: '#fff',
          },
        }}
      >
        Join the Menttalk Premium
      </Button>
    </Box>
  );
};

export default EventNetworkCTA;
