import React, { useState } from 'react';
import { createBooking, verifyBookingPayment } from '../services/api';

const BookingPage = ({ timeBlockId, availabilityId }) => {
    const [loading, setLoading] = useState(false);

    const handleBooking = async () => {
        setLoading(true);
        try {
            const bookingData = await createBooking({ time_block_id: timeBlockId, availability_id: availabilityId });
            const options = {
                key: 'rzp_test_GY4iJFc1dQJzvQ',
                amount: bookingData.amount * 100, // in paise
                currency: 'INR',
                name: 'Mentorship Booking',
                description: 'Payment for booking a mentorship session',
                order_id: bookingData.razorpay_order_id,
                handler: async function (response) {
                    await verifyBookingPayment({
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_signature: response.razorpay_signature,
                    });
                    alert('Payment successful and booking verified.');
                },
                prefill: {
                    name: 'Your Name',
                    email: 'your-email@example.com',
                },
                theme: {
                    color: '#3399cc',
                },
            };
            const rzp = new window.Razorpay(options);
            rzp.open();
        } catch (error) {
            console.error('Error booking session:', error);
            alert('Error booking session.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <button onClick={handleBooking} disabled={loading}>
                {loading ? 'Processing...' : 'Book Now'}
            </button>
        </div>
    );
};

export default BookingPage;