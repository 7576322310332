import React, { useState, useEffect } from 'react';
import { fetchEvents, createEventRegistration, updateEventPaymentStatus, verifyEventPayment } from '../services/api'; // Import necessary functions
import LightNavBar from '../components/LightNavBar';
import EventUpcoming from '../components/EventsUpcoming';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadEvents();
  }, []);

  const loadEvents = async () => {
    try {
      setLoading(true);
      const eventsData = await fetchEvents();
      setEvents(eventsData);
    } catch (error) {
      setError('Error fetching events. Please try again later.');
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBooking = async (eventId) => {
    try {
      // Create the event registration and get the necessary payment details
      const { registration_id, razorpay_order_id, amount } = await createEventRegistration(eventId);

      const options = {
        key: 'rzp_test_GY4iJFc1dQJzvQ', // Replace with your Razorpay API key
        amount: amount * 100, // Razorpay accepts amount in paise
        currency: 'INR',
        name: 'Your Company Name',
        description: 'Event Registration',
        order_id: razorpay_order_id,
        handler: async function (response) {
          
            console.log('Payment Response:', response);
            try {
            // Update the payment status using the registration_id
            await updateEventPaymentStatus(registration_id, response.razorpay_payment_id, response.razorpay_signature);
            

            // Verify the payment with Razorpay
            await verifyEventPayment ({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            });
            
            alert('Payment verified and registration completed.');
             // Redirect to the events page after successful registration
          } catch (error) {
            console.error('Payment verification failed:', error);
            alert('Payment verification failed. Please contact support.');
          }
        },
        prefill: {
          name: 'User Name', // Fetch this from the user's session or profile
          email: 'user@example.com', // Fetch this from the user's session or profile
        },
        notes: {
          address: 'Corporate Office',
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error during payment process:', error.response?.data || error.message);
      alert('Error during payment process. Please try again.');
    }
  };

  return (
    <div>
      <LightNavBar/>
      <EventUpcoming/>
      <h1>Events</h1>
      {loading ? (
        <p>Loading events...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ul>
          {events.map((event) => (
            <li key={event.id}>
              <h2>{event.title}</h2>
              <p>{event.description}</p>
              <p><strong>Date:</strong> {event.date}</p>
              <p><strong>Time:</strong> {event.start_time} - {event.end_time}</p>
              <p><strong>Location:</strong> {event.location}</p>
              <p><strong>Mentor:</strong> {event.mentor_name}</p>
              <p><strong>Price:</strong> ₹{event.price}</p>
              <button onClick={() => handleBooking(event.id)}>Register & Pay</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Events;
