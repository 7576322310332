import React from 'react';
import { Box, Typography, Card, Avatar, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LightNavBar from '../components/LightNavBar';

const BookingConfirmation = ({ bookingDetails }) => {
  return (
    <Box
    
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#fbe889',
      }}
    >
    <LightNavBar/>
      <Card
        sx={{
          padding: '20px',
          borderRadius: '15px',
          width: '400px',
          textAlign: 'center',
        }}
      >
        <Avatar
          sx={{
            margin: 'auto',
            width: '60px',
            height: '60px',
            marginBottom: '10px',
          }}
          src={bookingDetails?.profilePicture }
          alt={bookingDetails?.mentor}
        />
        <CheckCircleIcon
          sx={{ fontSize: '60px', color: 'green', marginBottom: '10px' }}
        />
        <Typography variant="h6" fontWeight="bold">
          Booking Confirmed
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          for your session with {bookingDetails?.mentor}
        </Typography>
        <Card
          sx={{
            backgroundColor: '#F1F1F1',
            padding: '10px',
            borderRadius: '10px',
            marginBottom: '20px',
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            {bookingDetails?.date}
          </Typography>
          <Typography variant="body2">
            {bookingDetails?.startTime} - {bookingDetails?.endTime}
          </Typography>
        </Card>
        <Typography variant="caption">
          Meeting details have been sent to your email.
        </Typography>

        <Box sx={{ marginTop: '20px' }}>
        
          <Button
             href={'/mentee-dashboard'}
            variant="outlined"
            fullWidth
            sx={{ marginBottom: '10px' }}
          >
            Explore Your Bookings
          </Button>
          <Button variant="contained" fullWidth>
            Visit Help Center
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default BookingConfirmation;
