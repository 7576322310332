import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Avatar, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  createEventRegistration,
  updateEventPaymentStatus,
  verifyEventPayment,
} from '../services/api'; // Import necessary functions for payment

const EventUpcoming = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch upcoming events from API
    const fetchEvents = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/events/');
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, []);

  if (!events.length) {
    return <Typography>Loading events...</Typography>;
  }

  const isAuthenticated = () => {
    // You can check authentication by looking for a token or session in localStorage
    return localStorage.getItem('token') !== null;
  };

  // Handle booking and payment for the event
  const handleBooking = async (eventId) => {
    if (!isAuthenticated()) {
      // If the user is not authenticated, redirect to login
      localStorage.setItem('redirectEventId', eventId); // Store the eventId for later
      navigate('/login'); // Redirect to login
      return;
    }

    try {
      // Create the event registration and get the necessary payment details
      const { registration_id, razorpay_order_id, amount } = await createEventRegistration(eventId);

      const options = {
        key: 'rzp_test_GY4iJFc1dQJzvQ', // Replace with your Razorpay API key
        amount: amount * 100, // Razorpay accepts amount in paise
        currency: 'INR',
        name: 'Your Company Name',
        description: 'Event Registration',
        order_id: razorpay_order_id,
        handler: async function (response) {
          console.log('Payment Response:', response);
          try {
            // Update the payment status using the registration_id
            await updateEventPaymentStatus(registration_id, response.razorpay_payment_id, response.razorpay_signature);

            // Verify the payment with Razorpay
            await verifyEventPayment({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            });

            alert('Payment verified and registration completed.');
          } catch (error) {
            console.error('Payment verification failed:', error);
            alert('Payment verification failed. Please contact support.');
          }
        },
        prefill: {
          name: 'User Name', // Fetch this from the user's session or profile
          email: 'user@example.com', // Fetch this from the user's session or profile
        },
        notes: {
          address: 'Corporate Office',
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error during payment process:', error.response?.data || error.message);
      alert('Error during payment process. Please try again.');
    }
  };
  const handleEventClick = (id, slug) => {
    navigate(`/events/${slug}`, { state: { id } }); // Navigate to event details using slug, and pass id via state
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '50px 0',
        backgroundColor: '#f8f9fa',
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Upcoming Events
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Browse past and future events happening in your city.
        </Typography>
      </Box>

      <Grid container spacing={4} justifyContent="center">
        {events.map((event) => (
          <Grid item xs={12} md={8} key={event.id}>
            <Box
              sx={{
                backgroundImage: `url(${event.background_image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '12px',
                padding: '20px',
                color: '#fff',
                position: 'relative',
                overflow: 'hidden',
              }}
              onClick={() => handleEventClick(event.id, event.slug)} // Send ID via state, use slug in URL
            >
              {/* Event Image */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <Box sx={{ height: '100%' }}>
                    <img
                      src={event.photo}
                      alt={event.title}
                      style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                    />
                  </Box>
                </Grid>

                {/* Event Details */}
                <Grid item xs={12} sm={7}>
                  <Box
                    sx={{
                      background: 'rgba(0, 0, 0, 0.6)',
                      padding: '20px',
                      borderRadius: '12px',
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#fff' }}>
                      {event.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#ddd' }}>
                      {event.location} • {new Date(event.date).toLocaleDateString()} •{' '}
                      {new Date(event.start_time).toLocaleTimeString()} -{' '}
                      {new Date(event.end_time).toLocaleTimeString()}
                    </Typography>

                    {/* Participants */}
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#fff' }}>
                        Mentees participating:
                      </Typography>
                      <AvatarGroup participants={event.participants} />
                    </Box>

                    {/* Register Button */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          backgroundColor: '#ffcb05',
                          color: '#000',
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: '#f6b500',
                          },
                        }}
                        onClick={() => handleBooking(event.id)} // Trigger the payment process
                      >
                        Register Now
                      </Button>
                      <IconButton sx={{ color: '#fff' }}>
                        <ChevronRightIcon fontSize="large" />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

// AvatarGroup component for participants
const AvatarGroup = ({ participants }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
    {participants.map((participant, index) => (
      <Avatar
        key={index}
        alt={participant}
        src={`https://ui-avatars.com/api/?name=${participant}&background=random`}
        sx={{ marginLeft: '-10px', border: '2px solid #fff' }}
      />
    ))}
  </Box>
);

export default EventUpcoming;
