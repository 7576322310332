import React from 'react';
import { Box, Typography, Avatar, Button, Chip } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const MentorCard = ({ mentor }) => {
  const navigate = useNavigate();  // Initialize navigate

  const handleViewProfile = () => {
    // Navigate to MentorDetails page with the mentor's username in the URL and pass id in state
    navigate(`/mentors/${mentor.username}`, { state: { id: mentor.id } });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #e0e0e0',
        borderRadius: '20px',
        padding: '16px',
        marginBottom: '20px',
        backgroundColor: '#fff',
      }}
    >
      {/* Left Section: Profile Info */}
      <Box sx={{ flex: '0 0 150px', marginRight: '16px' }}>
        <Avatar
          src={mentor.profile_picture}
          alt={mentor.name}
          sx={{ width: '100%', height: '150px', borderRadius: '20px' }}
        />
        <Typography
          variant="h6"
          sx={{ textAlign: 'center', fontWeight: 'bold', mt: 1 }}
        >
          {mentor.name}
        </Typography>
        <Box sx={{ textAlign: 'center', mt: 1 }}>
          <StarIcon sx={{ color: '#fb2f60', fontSize: '20px', verticalAlign: 'middle' }} />
          <Typography variant="body2" sx={{ display: 'inline', fontWeight: 'bold' }}>
            Star Mentor
          </Typography>
        </Box>
      </Box>

      {/* Middle Section: Details */}
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center', mb: 1, color: '#888' }}
        >
          <LocationOnIcon fontSize="small" /> {mentor.location || 'Location not provided'}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <VerifiedUserIcon fontSize="small" sx={{ color: '#388e3c' }} />
          <Typography variant="body2" sx={{ ml: 1, fontWeight: 'bold' }}>
            {mentor.reviews.length} reviews
          </Typography>
        </Box>
        <Typography variant="body2" sx={{ color: '#555', mb: 2 }}>
          {mentor.description || 'No description available'}
        </Typography>
        <Box sx={{ mb: 1 }}>
          {mentor.expertise.map((expert, index) => (
            <Chip
              key={index}
              label={expert.name}
              variant="outlined"
              sx={{ marginRight: '8px', marginBottom: '8px' }}
            />
          ))}
        </Box>
        <Typography variant="caption" color="textSecondary">
          For: Working Professionals
        </Typography>
      </Box>

      {/* Right Section: Booking Details */}
      <Box sx={{ minWidth: '180px', textAlign: 'center', paddingLeft: '20px', borderLeft: '1px solid #e0e0e0' }}>
        {/* Pricing Info */}
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fb2f60', mb: 2 }}>
          ₹{mentor.mentor_time_blocks[0]?.price || 'N/A'} / Per Session
        </Typography>

        {/* View Profile and Book Trial */}
        <Button
          variant="contained"
          fullWidth
          sx={{ mb: 1, backgroundColor: '#fb2f60', color: '#fff', '&:hover': { backgroundColor: '#c0332b' } }}
          onClick={handleViewProfile}  // Trigger navigation on click
        >
          View Profile
        </Button>
        <Button variant="outlined" fullWidth>
          Book a Free Trial
        </Button>
      </Box>
    </Box>
  );
};

export default MentorCard;
