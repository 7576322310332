import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, IconButton } from '@mui/material';
import axios from 'axios';
import LinkedInIcon from '@mui/icons-material/LinkedIn'; // Import LinkedIn icon

const Team = () => {
  const [teamData, setTeamData] = useState(null);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/about/1'); // Replace with correct API URL
        setTeamData(response.data);
      } catch (error) {
        console.error('Error fetching team data:', error);
      }
    };
    fetchTeamData();
  }, []);

  if (!teamData) {
    return <div>Loading...</div>;
  }

  const { team_intro_text, teammates } = teamData;

  return (
    <Box
      sx={{
        width: '100%',
        py: 8,
        background: 'linear-gradient(180deg, #580017 0%, #c0332b 100%)', // Gradient background
        color: '#fff',
        textAlign: 'center',
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
      }}
    >
      {/* Team Intro Section */}
      <Box sx={{ maxWidth: '800px', margin: '0 auto', mb: 6 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3 }}>
          The Team
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '18px', lineHeight: '1.7' }}>
          {team_intro_text}
        </Typography>
      </Box>

      {/* Team Member Grid */}
      <Grid container spacing={4} justifyContent="center">
        {teammates.map((teammate, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                backgroundColor: '#fff', // Dark card background
                borderRadius: '12px',
                overflow: 'hidden',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)', // Subtle shadow
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-8px)', // Smaller hover effect
                },
                textAlign: 'center',
                position: 'relative',
                width: '280px', // Compact width
                height: '400px', // Compact height
              }}
            >
              {/* Full Image Section */}
              <Box
                sx={{
                  width: '100%',
                  height: '250px', // Set the height for square images
                  overflow: 'hidden',
                }}
              >
                <img
                  src={teammate.photo}
                  alt={teammate.name}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '12px', // Rounded top corners
                  }}
                />
              </Box>

              {/* Team Member Details */}
              <CardContent sx={{ padding: '16px' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#000000', fontSize: '16px' }}>
                  {teammate.name}
                </Typography>

                <Typography variant="body2" sx={{ color: '#000000', fontStyle: 'italic', fontSize: '12px' }}>
                  {teammate.position}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: '#000000',
                    fontSize: '12px', // Smaller font size for bio
                    lineHeight: '1.4',
                    marginTop: '8px',
                    marginBottom: '12px',
                    maxHeight: '50px',
                    overflow: 'hidden', // Ensures text fits in a compact space
                  }}
                >
                  {teammate.bio}
                </Typography>

                {/* LinkedIn Icon */}
                <IconButton
                  aria-label="LinkedIn"
                  sx={{
                    position: 'absolute',
                    bottom: '16px',
                    right: '16px',
                    color: '#fff',
                    backgroundColor: '#0077B5',
                    '&:hover': {
                      backgroundColor: '#005582',
                    },
                  }}
                  href={teammate.linkedinurl} // Assuming LinkedIn URL is provided
                >
                  <LinkedInIcon />
                </IconButton>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;
