import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Link, Container, IconButton } from '@mui/material';
import { LinkedIn, Instagram, Facebook, YouTube } from '@mui/icons-material';
import axios from 'axios';

const Footer = () => {
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/footer/1/');
        setFooterData(response.data);
      } catch (error) {
        console.error('Error fetching footer data:', error);
      }
    };
    fetchFooterData();
  }, []);

  if (!footerData) {
    return <Typography>Loading Footer...</Typography>;
  }

  const { about_text, hashtag, copyright, email, social_links, links, company_name } = footerData;

  // Separate links based on their section (company, quick, compliance)
  const companyLinks = links.filter(link => link.section === 'company');
  const quickLinks = links.filter(link => link.section === 'quick');
  const complianceLinks = links.filter(link => link.section === 'compliance');

  return (
    <Box sx={{ backgroundColor: '#590017', py: 5, color: '#fff' }}>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          {/* About Section */}
          <Grid item xs={12} md={4}>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {company_name}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {about_text}
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
              {hashtag}
            </Typography>
          </Grid>

          {/* Company Links */}
          <Grid item xs={12} md={2}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Company
            </Typography>
            {companyLinks.map((link, index) => (
              <Link key={index} href={link.url} underline="none" sx={{ display: 'block', color: '#fff', mt: 1 }}>
                {link.name}
              </Link>
            ))}
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} md={2}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Quick Links
            </Typography>
            {quickLinks.map((link, index) => (
              <Link key={index} href={link.url} underline="none" sx={{ display: 'block', color: '#fff', mt: 1 }}>
                {link.name}
              </Link>
            ))}
          </Grid>

          {/* Compliance Links */}
          <Grid item xs={12} md={2}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Compliance
            </Typography>
            {complianceLinks.map((link, index) => (
              <Link key={index} href={link.url} underline="none" sx={{ display: 'block', color: '#fff', mt: 1 }}>
                {link.name}
              </Link>
            ))}
          </Grid>
        </Grid>

        {/* Social Links and Copyright */}
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {copyright}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {email}
          </Typography>
          <Box>
            {social_links?.linkedin && (
              <IconButton href={social_links.linkedin} target="_blank" sx={{ color: '#fff' }}>
                <LinkedIn />
              </IconButton>
            )}
            {social_links?.instagram && (
              <IconButton href={social_links.instagram} target="_blank" sx={{ color: '#fff' }}>
                <Instagram />
              </IconButton>
            )}
            {social_links?.facebook && (
              <IconButton href={social_links.facebook} target="_blank" sx={{ color: '#fff' }}>
                <Facebook />
              </IconButton>
            )}
            {social_links?.youtube && (
              <IconButton href={social_links.youtube} target="_blank" sx={{ color: '#fff' }}>
                <YouTube />
              </IconButton>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
