import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid, Card, Avatar } from '@mui/material';
import axios from 'axios';

// Mentoring CTA Component
const MentoringCTA = () => {
  const [ctaData, setCtaData] = useState(null);

  useEffect(() => {
    const fetchCtaData = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/mentoringcta/1/'); // Replace with the correct API endpoint
        setCtaData(response.data);
      } catch (error) {
        console.error('Error fetching CTA data:', error);
      }
    };
    fetchCtaData();
  }, []);

  if (!ctaData) {
    return <div>Loading...</div>;
  }

  const { title, subtitle, description, video_url, top_mentors_count, happy_mentees_count, sessions_done_count, rating_stars, rating_label } = ctaData;

  // Extract the YouTube video ID from the video_url if a full YouTube link is provided
  const videoID = video_url.includes('youtube.com') ? video_url.split('v=')[1] : video_url;

  return (
    <Box sx={{ width: '100%', py: 6, backgroundColor: '#ffebf0' }}>
      {/* Full-width container for content */}
      <Box sx={{ maxWidth: '1200px', margin: '0 auto', px: 4 }}>
        <Grid container spacing={4} alignItems="center">
          {/* Text and Buttons Section */}
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="overline" color="textSecondary">{subtitle}</Typography>
              <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#590119' }}>{title}</Typography>
              <Typography variant="body1" color="textSecondary" sx={{ mt: 2, mb: 3 }}>{description}</Typography>

              <Box>
              <Button 
  variant="contained" 
  sx={{ 
    backgroundColor: '#590119', 
    color: '#fff', 
    mr: 2,
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-100%',
      width: '200%',
      height: '100%',
      background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%)',
      transition: 'all 0.5s ease',
      animation: 'shine 3s infinite',
    },
    '@keyframes shine': {
      '0%': {
        left: '-100%',
      },
      '100%': {
        left: '100%',
      },
    },
  }}
>
  Find Your Mentor
</Button>

                <Button variant="outlined" sx={{ borderColor: '#590119', color: '#590119' }}>
                  Contact Us
                </Button>
              </Box>

              {/* Rating Section */}
              <Box display="flex" alignItems="center" sx={{ mt: 3 }}>
                <Avatar src="/default-rating-icon.png" sx={{ width: 40, height: 40, mr: 2 }} />
                <Typography variant="body2" color="textSecondary">
                  <strong>{rating_stars}/5</strong> {rating_label}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Video Section */}
          <Grid item xs={12} md={6}>
            <Box sx={{ position: 'relative', height: 'auto', paddingTop: '56.25%', backgroundColor: '#fff', borderRadius: '20px', boxShadow: 2 }}>
              <iframe
                width="100%"
                height="100%"
                style={{ position: 'absolute', top: 0, left: 0, borderRadius: '20px' }}
                src={`https://www.youtube.com/embed/${videoID}`}
                title="YouTube Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Statistics Section - Full-width, centered with cards */}
      <Box sx={{ mt: 4, width: '100%', py: 4 }}>
        <Box sx={{ maxWidth: '1200px', margin: '0 auto', px: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Card sx={{ textAlign: 'center', padding: 3, backgroundColor: '#ec2d5b', borderRadius: '20px', boxShadow: 2 }}>
                <Typography variant="h5" color="#fff" sx={{ fontWeight: 'bold' }}>{top_mentors_count}+</Typography>
                <Typography variant="body2" color="white">Top Mentors</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{ textAlign: 'center', padding: 3, backgroundColor: '#ec2d5b', borderRadius: '20px', boxShadow: 2 }}>
                <Typography variant="h5" color="#fff" sx={{ fontWeight: 'bold' }}>{happy_mentees_count}+</Typography>
                <Typography variant="body2" color="white">Happy Mentees</Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={{ textAlign: 'center', padding: 3, backgroundColor: '#ec2d5b', borderRadius: '20px', boxShadow: 2 }}>
                <Typography variant="h5" color="#fff" sx={{ fontWeight: 'bold' }}>{sessions_done_count}+</Typography>
                <Typography variant="body2" color="white">Sessions Done</Typography>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default MentoringCTA;
