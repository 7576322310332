import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, Box, CircularProgress, Button, CardMedia } from '@mui/material';
import { fetchMenteeRegisteredEvents } from '../services/api'; // API call to fetch registered events
import Layout from './Layout'; // Assuming your Layout component is in the same folder

const MenteeEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleCount, setVisibleCount] = useState(4); // Number of events to show initially


  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventsData = await fetchMenteeRegisteredEvents();
        console.log(eventsData); // Log the events to check the field for image
        setEvents(eventsData);
      } catch (err) {
        setError('Failed to fetch events');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const loadMoreEvents = () => {
    setVisibleCount((prevCount) => prevCount + 4); // Load more events on each click
  };

  // Handle event card click and navigate to event details page


  const BASE_URL = 'http://52.90.169.75:8000'; // Replace with your actual backend URL

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography variant="body1" color="error">{error}</Typography>;
  }

  return (
    <Layout>
      <Typography variant="h4" gutterBottom>Your Registered Events</Typography>
      <Grid container spacing={3} justifyContent="center"> {/* Increased spacing to 3 */}
        {events.slice(0, visibleCount).map((event) => (
          <Grid item key={event.id}>
            <Card
              sx={{
                padding: 2,
                borderRadius: '20px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Softer shadow for better look
                width: '250px', // Set card width to 250px
                height: '100%',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)', // Slight hover effect
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', // Shadow grows on hover
                },
              }}
            >
              {/* Event Image */}
              <CardMedia
                component="img"
                height="140"
                image={`${BASE_URL}${event.photo}`} // Construct full URL for the image
                alt={event.event_title}
              />

              <CardContent sx={{ textAlign: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                  {event.event_title}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Mentor: {event.mentor_name}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Registration Date: {new Date(event.registration_date).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Payment Status: {event.payment_status}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {visibleCount < events.length && (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button variant="contained" color="primary" onClick={loadMoreEvents} sx={{ backgroundColor: '#ec2d5b' }}>
            Load More
          </Button>
        </Box>
      )}
    </Layout>
  );
};

export default MenteeEvents;
