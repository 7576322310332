import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import axios from 'axios';

const AboutStory = () => {
  const [storyData, setStoryData] = useState(null);

  useEffect(() => {
    const fetchStoryData = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/about/1'); // Replace with correct API URL
        setStoryData(response.data);
      } catch (error) {
        console.error('Error fetching story data:', error);
      }
    };
    fetchStoryData();
  }, []);

  if (!storyData) {
    return <div>Loading...</div>;
  }

  const { sub_intro_image1, sub_intro_image2, about_us_story } = storyData;

  return (
    <Box
      sx={{
        width: '100%',
        py: 8,
        background: 'linear-gradient(360deg, #580017 0%, #c0332b 100%)', // Dark blue to purple gradient background
        color: '#fff',
        textAlign: 'center',
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
      }}
    >
        
      {/* Images Section */}
      
      <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
        {sub_intro_image1 && (
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                width: '100%',
                borderRadius: '12px',
                overflow: 'hidden',
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
              }}
            >
              <img
                src={sub_intro_image1}
                alt="Intro section, first "  // Updated alt text
                style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
              />
            </Box>
          </Grid>
        )}
        {sub_intro_image2 && (
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                width: '100%',
                borderRadius: '12px',
                overflow: 'hidden',
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
              }}
            >
              <img
                src={sub_intro_image2}
                alt="Intro section, second " // Updated alt text
                style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      {/* Story Section */}
      <Box sx={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
        <Typography
          variant="button"
          sx={{
            fontSize: '12px',
            color: '#90A4AE',
            letterSpacing: '1px',
            mb: 2,
            display: 'inline-block',
            textTransform: 'uppercase',
          }}
        >
          The Story
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            mb: 3,
          }}
        >
          How it all started
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontSize: '18px',
            lineHeight: '1.7',
            mb: 3,
          }}
        >
          {about_us_story.split('\n').map((paragraph, index) => (
            <Box key={index} component="p" sx={{ mb: 2 }}>
              {paragraph}
            </Box>
          ))}
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutStory;
