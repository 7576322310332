import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Typography, Box } from '@mui/material';
import { fetchEvent, registerForEvent } from '../services/api';

const EventRegistration = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);

  const fetchEventData = useCallback(async () => {
    try {
      const eventData = await fetchEvent(eventId);
      setEvent(eventData);
    } catch (error) {
      console.error('Error fetching event:', error);
    }
  }, [eventId]);

  useEffect(() => {
    fetchEventData();
  }, [fetchEventData]);

  const handleRegistration = async () => {
    try {
      const payload = { event: eventId };
      await registerForEvent(payload);
      alert('Registered successfully');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        alert(error.response.data.detail);
      } else {
        alert('You have already registered for the event');
      }
      console.error('Error registering for event:', error);
    }
  };

  if (!event) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Typography variant="h4">{event.title}</Typography>
      <Typography variant="body1">{event.description}</Typography>
      <Typography variant="body1">{`${event.date} | ${event.start_time} - ${event.end_time}`}</Typography>
      <Typography variant="body1">{event.location}</Typography>
      <Typography variant="body1">{`Takeaways: ${event.takeaways}`}</Typography>
      {event.introductory_video && (
        <div>
          <Typography variant="body1">Introductory Video:</Typography>
          <iframe width="560" height="315" src={event.introductory_video} title="Introductory Video" frameBorder="0" allowFullScreen></iframe>
        </div>
      )}
      <Typography variant="body1">{`Price: $${event.price}`}</Typography>
      <Button variant="contained" color="primary" onClick={handleRegistration} style={{ marginTop: '20px' }}>
        Register
      </Button>
    </Box>
  );
};

export default EventRegistration;
