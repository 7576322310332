// src/components/BrowseMentors.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import axios from 'axios';
import MentorCard from './MentorCard'; // Import the MentorCard component

const BrowseMentors = () => {
  const [mentors, setMentors] = useState([]);
  const [filteredMentors, setFilteredMentors] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/mentors/');
        setMentors(response.data);
        setFilteredMentors(response.data);
      } catch (error) {
        console.error('Error fetching mentors:', error);
      }
    };

    fetchMentors();
  }, []);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    const filtered = mentors.filter((mentor) =>
      mentor.expertise.some((exp) => exp.name.toLowerCase().includes(e.target.value.toLowerCase()))
    );
    setFilteredMentors(filtered);
  };

  return (
    <Box sx={{ padding: '40px 0', backgroundColor: '#f8f9fa' }}>
      <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
        Browse Mentors
      </Typography>

      {/* Filter Bar */}
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <input
          type="text"
          placeholder="Filter by Expertise"
          value={filter}
          onChange={handleFilterChange}
          style={{ padding: '8px 16px', borderRadius: '8px', border: '1px solid #ccc', width: '300px' }}
        />
      </Box>

      <Grid container spacing={4} justifyContent="center">
        {filteredMentors.map((mentor) => (
          <Grid item xs={12} md={8} key={mentor.id}>
            <MentorCard mentor={mentor} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BrowseMentors;
