import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { loginUser, createEventRegistration, updateEventPaymentStatus, verifyEventPayment } from '../services/api';
import axios from 'axios';

// Import all mentorship and training-related icons
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WorkIcon from '@mui/icons-material/Work';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ForumIcon from '@mui/icons-material/Forum';
import GroupIcon from '@mui/icons-material/Group';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StarIcon from '@mui/icons-material/Star';
import AssessmentIcon from '@mui/icons-material/Assessment';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const Login = ({ setUserType }) => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [logo, setLogo] = useState(null);
  const [errorMessage, setErrorMessage] = useState(''); // State to store error message
  const navigate = useNavigate();

  // Fetch the logo from API
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/navigation/api/menus/');
        setLogo(response.data.logos.normal_logo);
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Reset error message on submit
    try {
      const data = await loginUser(credentials);
      localStorage.setItem('token', data.token);
      localStorage.setItem('user_type', data.user_type);
      setUserType(data.user_type);

      // Check if there's an event stored for registration
      const eventId = localStorage.getItem('eventId');
      if (eventId) {
        await handleEventRegistration(eventId);
        localStorage.removeItem('eventId'); // Clear the event after registration
      }

      // Check for redirect path stored before login, if any
      const redirectPath = localStorage.getItem('redirectPath') || '/browse-mentors';
      localStorage.removeItem('redirectPath'); // Clear redirect path after use
      navigate(redirectPath);
    } catch (error) {
      console.error('Error logging in:', error);
      setErrorMessage('Invalid username or password. Please try again.');
    }
  };

  // Function to handle event registration
  const handleEventRegistration = async (eventId) => {
    try {
      const { registration_id, razorpay_order_id, amount } = await createEventRegistration(eventId);

      const options = {
        key: 'rzp_test_GY4iJFc1dQJzvQ', // Replace with your Razorpay API key
        amount: amount * 100, // Razorpay accepts amount in paise
        currency: 'INR',
        name: 'Your Company Name',
        description: 'Event Registration',
        order_id: razorpay_order_id,
        handler: async function (response) {
          try {
            await updateEventPaymentStatus(
              registration_id,
              response.razorpay_payment_id,
              response.razorpay_signature
            );

            await verifyEventPayment({
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            });

            alert('Payment verified and registration completed.');
          } catch (error) {
            console.error('Payment verification failed:', error);
            alert('Payment verification failed. Please contact support.');
          }
        },
        prefill: {
          name: 'User Name', // Fetch this from user profile/session
          email: 'user@example.com', // Fetch this from user profile/session
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error during payment process:', error.response?.data || error.message);
      alert('Error during payment process. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #c0332b 0%, #fed7db 100%)',
        backgroundImage: `
        radial-gradient(at 10% 20%, rgba(192, 51, 43, 0.3) 0%, transparent 50%),
        radial-gradient(at 80% 80%, rgba(88, 0, 23, 0.4) 0%, transparent 50%),
        radial-gradient(at 50% 50%, rgba(251, 47, 96, 0.2) 0%, transparent 40%),
        radial-gradient(at 90% 10%, rgba(254, 215, 219, 0.3) 0%, transparent 50%)
      `,
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Floating mentorship-related icons */}
      <SchoolIcon
        sx={{
          position: 'absolute',
          top: '5%',
          left: '10%',
          fontSize: 80,
          color: '#c0332b',
          opacity: 0.3,
          animation: 'float 10s ease-in-out infinite',
        }}
      />
      <PersonIcon
        sx={{
          position: 'absolute',
          top: '30%',
          right: '15%',
          fontSize: 70,
          color: '#580017',
          opacity: 0.3,
          animation: 'float 8s ease-in-out infinite',
        }}
      />
      <SupervisorAccountIcon
        sx={{
          position: 'absolute',
          top: '40%',
          left: '5%',
          fontSize: 90,
          color: '#ff6f61',
          opacity: 0.3,
          animation: 'float 12s ease-in-out infinite',
        }}
      />
      <WorkIcon
        sx={{
          position: 'absolute',
          top: '15%',
          right: '20%',
          fontSize: 100,
          color: '#c0332b',
          opacity: 0.2,
          animation: 'float 15s ease-in-out infinite',
        }}
      />
      <BusinessCenterIcon
        sx={{
          position: 'absolute',
          bottom: '10%',
          left: '10%',
          fontSize: 90,
          color: '#4A148C',
          opacity: 0.3,
          animation: 'float 10s ease-in-out infinite',
        }}
      />
      <EmojiObjectsIcon
        sx={{
          position: 'absolute',
          bottom: '20%',
          right: '10%',
          fontSize: 100,
          color: '#ffc107',
          opacity: 0.4,
          animation: 'float 7s ease-in-out infinite',
        }}
      />
      <ForumIcon
        sx={{
          position: 'absolute',
          bottom: '30%',
          left: '25%',
          fontSize: 80,
          color: '#0077B5',
          opacity: 0.3,
          animation: 'float 13s ease-in-out infinite',
        }}
      />
      <GroupIcon
        sx={{
          position: 'absolute',
          bottom: '35%',
          right: '15%',
          fontSize: 90,
          color: '#4caf50',
          opacity: 0.2,
          animation: 'float 9s ease-in-out infinite',
        }}
      />
      <MenuBookIcon
        sx={{
          position: 'absolute',
          top: '50%',
          right: '5%',
          fontSize: 70,
          color: '#c0332b',
          opacity: 0.2,
          animation: 'float 14s ease-in-out infinite',
        }}
      />
      <LaptopMacIcon
        sx={{
          position: 'absolute',
          top: '40%',
          left: '30%',
          fontSize: 100,
          color: '#1976d2',
          opacity: 0.2,
          animation: 'float 16s ease-in-out infinite',
        }}
      />
      <LightbulbIcon
        sx={{
          position: 'absolute',
          top: '35%',
          right: '30%',
          fontSize: 80,
          color: '#ffeb3b',
          opacity: 0.3,
          animation: 'float 11s ease-in-out infinite',
        }}
      />

      {/* Newly added floating icons */}
      <SchoolOutlinedIcon
        sx={{
          position: 'absolute',
          bottom: '40%',
          left: '5%',
          fontSize: 85,
          color: '#c0332b',
          opacity: 0.3,
          animation: 'float 9s ease-in-out infinite',
        }}
      />
      <StarIcon
        sx={{
          position: 'absolute',
          top: '5%',
          right: '5%',
          fontSize: 70,
          color: '#ffeb3b',
          opacity: 0.3,
          animation: 'float 12s ease-in-out infinite',
        }}
      />
      <AssessmentIcon
        sx={{
          position: 'absolute',
          top: '30%',
          left: '25%',
          fontSize: 80,
          color: '#4caf50',
          opacity: 0.3,
          animation: 'float 11s ease-in-out infinite',
        }}
      />
      <VideoCameraFrontIcon
        sx={{
          position: 'absolute',
          bottom: '5%',
          right: '25%',
          fontSize: 90,
          color: '#1976d2',
          opacity: 0.3,
          animation: 'float 8s ease-in-out infinite',
        }}
      />
      <CastForEducationIcon
        sx={{
          position: 'absolute',
          bottom: '10%',
          left: '15%',
          fontSize: 90,
          color: '#1976d2',
          opacity: 0.3,
          animation: 'float 12s ease-in-out infinite',
        }}
      />
      <AutoGraphIcon
        sx={{
          position: 'absolute',
          bottom: '5%',
          right: '10%',
          fontSize: 70,
          color: '#4A148C',
          opacity: 0.3,
          animation: 'float 10s ease-in-out infinite',
        }}
      />

      {/* Login form container */}
      <Container
        maxWidth="xs"
        sx={{
          backgroundColor: '#fff',
          padding: 4,
          borderRadius: '12px',
          boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
          zIndex: 2,
        }}
      >
        {/* Display Logo */}
        {logo && (
          <Box sx={{ textAlign: 'center', marginBottom: 3 }}>
            <img
              src={logo}
              alt="Logo"
              style={{ height: '40px' }}
            />
          </Box>
        )}

        {/* Title */}
        <Box sx={{ textAlign: 'center', marginBottom: 3 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Welcome back, sign in.
          </Typography>
        </Box>

        {/* Display Error Message if Any */}
        {errorMessage && (
          <Typography color="error" sx={{ marginBottom: 2 }}>
            {errorMessage}
          </Typography>
        )}

        {/* Login Form */}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            name="username"
            value={credentials.username}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={credentials.password}
            onChange={handleChange}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
            <Link href="/forgot-password" variant="body2" sx={{ color: '#580017' }}>
              Forgot password?
            </Link>
          </Box>

          {/* Login Button */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#c0332b',
              color: '#fff',
              padding: '12px',
              marginTop: 2,
              '&:hover': {
                backgroundColor: '#580017',
              },
            }}
          >
            Sign in
          </Button>
        </form>

        {/* Sign Up Link */}
        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
          <Typography variant="body2">
            Don’t have an account?{' '}
            <Link href="/register" variant="body2" sx={{ fontWeight: 'bold', textDecoration: 'none', color: '#580017' }}>
              Sign up
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
