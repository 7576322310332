import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box, Typography, Avatar, Grid, Button, Card, Chip, Rating,
  Divider, Modal, Backdrop, Fade, Stepper, Step, StepLabel
} from '@mui/material';
import Calendar from 'react-calendar'; // For availability calendar
import 'react-calendar/dist/Calendar.css'; // Calendar CSS
import axios from 'axios';
import LightNavBar from '../components/LightNavBar'; 
import BookingConfirmation from './BookingConfirmation';

import { verifyBookinPayment, createBooking } from '../services/api'; 

const MentorDetails = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Initialize navigate for redirection
  const [mentor, setMentor] = useState(null);
  const [open, setOpen] = useState(false); 
  const [activeStep, setActiveStep] = useState(0); 
  const [selectedSlot, setSelectedSlot] = useState(null); 
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null); 
  const [availableSlots, setAvailableSlots] = useState([]); 
  const [selectedDate, setSelectedDate] = useState(null); 
  const [bookingLoading, setBookingLoading] = useState(false); 
  const [bookingConfirmed, setBookingConfirmed] = useState(false); // Booking success state
  const [confirmedBookingDetails, setConfirmedBookingDetails] = useState(null); // Store confirmed booking details

  const steps = ['Select Time Slot', 'Select Availability', 'Payment'];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const checkAuthentication = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        localStorage.setItem('redirectPath', window.location.pathname);
        navigate('/login'); // Redirect to login if user is not authenticated
      }
    } catch (error) {
      console.error('Error during authentication:', error);
    }
  }, [navigate]);

  // Effect to check authentication on component mount
  useEffect(() => {
    checkAuthentication(); // Call the memoized function
  }, [checkAuthentication]); // Include checkAuthentication in the dependency array


  useEffect(() => {
    const fetchMentorDetails = async () => {
      try {
        const response = await axios.get(`http://52.90.169.75:8000/api/mentors/${location.state?.id}/`);
        setMentor(response.data);
      } catch (error) {
        console.error('Error fetching mentor details:', error);
      }
    };

    const fetchAvailableSlots = async () => {
      try {
        const response = await axios.get(`http://52.90.169.75:8000/api/mentors/${location.state?.id}/available-slots/`);
        setAvailableSlots(response.data);
      } catch (error) {
        console.error('Error fetching available slots:', error);
      }
    };

    if (location.state?.id) {
      fetchMentorDetails();
      fetchAvailableSlots();
    }
  }, [location.state?.id]);

  const handlePayment = async () => {
    if (!selectedSlot || !selectedTimeSlot) {
      alert("Please select a time slot to book.");
      return;
    }

    setBookingLoading(true);

    try {
      const startTime = `${selectedSlot.date} ${selectedTimeSlot.start}`; 
      const endTime = `${selectedSlot.date} ${selectedTimeSlot.end}`; 

      const bookingData = {
        mentor_id: mentor.id,
        availability_id: selectedSlot.availability_id,
        time_slot_id: selectedTimeSlot.time_slot_id, 
        start_time: startTime,  
        end_time: endTime      
      };

      const bookingResponse = await createBooking(bookingData);
      const { razorpay_order_id, razorpay_key, amount, currency, booking_id } = bookingResponse;

      const options = {
        key: razorpay_key, 
        amount: amount * 100, 
        currency: currency, 
        name: "Mentorship Booking",
        description: `Book with ${mentor.name}`,
        order_id: razorpay_order_id,
        handler: async function (response) {
          const paymentData = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id, 
            razorpay_signature: response.razorpay_signature,
            booking_id: booking_id
          };

          const verifyResponse = await verifyBookinPayment(paymentData);

          if (verifyResponse.status === 'Payment successful!') {
            // Set booking as confirmed and store details
            setBookingConfirmed(true);
            setConfirmedBookingDetails({
              mentor: mentor.name,
              date: selectedSlot.date,
              startTime: selectedTimeSlot.start,
              endTime: selectedTimeSlot.end,
              profilePicture: mentor.profile_picture
            });
          } else {
            alert("Payment verification failed. Please try again.");
          }
        },
        prefill: { name: "", email: "", contact: "" },
        theme: { color: "#F37254" }
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (err) {
      console.error("Error in booking or payment initiation: ", err);
      alert("Failed to initiate booking. Please try again.");
    } finally {
      setBookingLoading(false);
    }
  };

  if (bookingConfirmed) {
    return (
      <BookingConfirmation
        bookingDetails={confirmedBookingDetails} // Pass booking details
      />
    );
  }

  const handleSlotSelection = (availability, timeSlot, timeSlotId) => {
    setSelectedSlot(availability);
    setSelectedTimeSlot({ ...timeSlot, time_slot_id: timeSlotId });
  };

  const renderExpertise = () => mentor?.expertise?.map((exp, index) => (
    <Chip key={index} label={exp.name} sx={{ margin: '5px' }} />
  ));

  const renderTools = () => mentor?.toolkits_used?.map((tool, index) => (
    <Chip key={index} label={tool.name} sx={{ margin: '5px' }} />
  ));

  const renderWorkExperiences = () => mentor?.work_experiences?.map((experience, index) => (
    <Card key={index} sx={{ padding: '15px', marginBottom: '10px' }}>
      <Typography variant="h6">{experience.company_name}</Typography>
      <Typography variant="body1">{experience.work_description}</Typography>
      <Typography variant="caption">
        {experience.currently_working ? 'Currently working here' : `Worked till ${experience.date_ended}`}
      </Typography>
    </Card>
  ));

  const renderReviews = () => mentor?.reviews?.map((review, index) => (
    <Card key={index} sx={{ padding: '15px', marginBottom: '10px' }}>
      <Typography variant="h6">{review.reviewer}</Typography>
      <Typography variant="body1">{review.content}</Typography>
      <Typography variant="caption">Rating: {review.rating} stars</Typography>
    </Card>
  ));

  if (!mentor) return <Typography>Loading mentor details...</Typography>;

  return (
    <Box>
      <LightNavBar />

      <Box sx={{ marginTop: '70px' }}>
        {/* Mentor Profile and Booking Section */}
        <Box sx={{ maxWidth: '100%', padding: '40px 0', backgroundColor: '#f8f9fa' }}>
          <Box sx={{ maxWidth: '100%', padding: '0 20px' }}>
            <Card sx={{ padding: '40px 20px', borderRadius: '20px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)' }}>
              <Grid container spacing={3}>
                {/* Mentor Information */}
                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Avatar
                    src={mentor.profile_picture}
                    alt={mentor.name}
                    sx={{ width: '150px', height: '150px', borderRadius: '50%' }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" fontWeight="bold">
                    {mentor.name} <Chip label="Star Mentor" sx={{ marginLeft: '10px', backgroundColor: '#ffde59' }} />
                  </Typography>
                  <Typography variant="h6" color="text.secondary" sx={{ marginTop: '10px' }}>
                    {mentor.description}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <Rating value={4.8} readOnly precision={0.5} sx={{ marginRight: '10px' }} />
                    <Typography variant="body2">4.8 (20 Reviews)</Typography>
                    <Typography sx={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                      <Chip label="9247+ Mentoring Mins" sx={{ marginRight: '10px', backgroundColor: '#ffde59' }} />
                      <Chip label="30+ Mentees" sx={{ backgroundColor: '#ffde59' }} />
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ marginTop: '20px', fontWeight: '500' }}>
                    5+ years of experience | Menttalk | Google Creative Campus
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: '#007bff', color: '#fff', textTransform: 'none', marginTop: '20px' }}
                    onClick={handleOpen}
                  >
                    Book Now
                  </Button>
                </Grid>

                {/* Booking Section */}
                <Grid item xs={12} md={3}>
                  <Card sx={{ padding: '20px', backgroundColor: '#f1f1f1', borderRadius: '10px', boxShadow: 'none' }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#ff6347' }}>
                      Free 1:1 Trial
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '5px', color: 'gray' }}>
                      Book a Free Trial to Plan Your Mentorship
                    </Typography>
                    <Divider sx={{ marginY: '10px' }} />
                    <Typography variant="body2" sx={{ marginBottom: '10px' }}>
                      Available Dates
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                      <Chip label="Wed 2 Oct" />
                      <Chip label="Sat 5 Oct" />
                      <Chip label="Sun 6 Oct" />
                    </Box>
                    <Typography variant="body2" sx={{ marginBottom: '10px' }}>
                      Available Slots
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                      <Chip label="1:00 PM" />
                      <Chip label="1:15 PM" />
                      <Chip label="1:30 PM" />
                    </Box>
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: '#007bff', color: '#fff', textTransform: 'none', width: '100%' }}
                    >
                      Book a Free Trial for Oct 2
                    </Button>
                  </Card>
                </Grid>
              </Grid>
            </Card>
            
          </Box>
        </Box>

        {/* Additional Sections: Bio, Expertise, Reviews */}
        <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: '0 20px' }}>
          {/* Bio Section */}
          <Card sx={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h6">Bio</Typography>
            <Typography variant="body1">{mentor.bio}</Typography>
          </Card>

          {/* Expertise Section */}
          <Card sx={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h6">Expertise</Typography>
            <Box>{renderExpertise()}</Box>
          </Card>

          {/* Tools Section */}
          <Card sx={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h6">Toolkits</Typography>
            <Box>{renderTools()}</Box>
          </Card>

          {/* Work Experience Section */}
          <Card sx={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h6">Work Experience</Typography>
            {renderWorkExperiences()}
          </Card>

          {/* Reviews Section */}
          <Card sx={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h6">Reviews</Typography>
            {renderReviews()}
          </Card>

          {/* Booking Modal */}
<Modal open={open} onClose={handleClose} BackdropComponent={Backdrop}>
  <Fade in={open}>
    <Box sx={modalStyle}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 && (
        <Box>
          <Typography>Select an Availability Date</Typography>
          <Calendar
            onChange={setSelectedDate}  // Select the date
            value={selectedDate}
            tileDisabled={({ date }) =>
              !availableSlots.some((avail) => new Date(avail.date).toDateString() === date.toDateString())
            }
          />
          <Divider sx={{ marginY: 2 }} />
          <Button onClick={handleNext} disabled={!selectedDate}>
            Next
          </Button>
        </Box>
      )}

      {activeStep === 1 && (
        <Box>
          <Typography>Select a Time Slot</Typography>
          {availableSlots
            .filter((availability) => new Date(availability.date).toDateString() === new Date(selectedDate).toDateString()) // Filter time slots based on selected date
            .map((availability) => (
              <div key={availability.availability_id}>
                <Typography>Date: {availability.date}</Typography>
                {availability.slots.map((slotType) => (
                  <div key={slotType.time_slot_id}>
                    <Typography>
                      {slotType.time_slot_duration} Minutes (₹{slotType.price})
                    </Typography>
                    {slotType.slots.map((slot, index) => (
                      <Button
                        key={index}
                        onClick={() => handleSlotSelection(availability, slot, slotType.time_slot_id)}
                      >
                        {slot.start} - {slot.end}
                      </Button>
                    ))}
                  </div>
                ))}
              </div>
          ))}
          <Divider sx={{ marginY: 2 }} />
          <Button onClick={handleNext} disabled={!selectedSlot}>
            Next
          </Button>
        </Box>
      )}

      {activeStep === 2 && (
        <Box>
          <Typography>Payment Details</Typography>
          <Button variant="contained" onClick={handlePayment} disabled={bookingLoading}>
            {bookingLoading ? "Processing..." : "Pay Now"}
          </Button>
        </Box>
      )}

      {activeStep > 0 && (
        <Button onClick={handleBack}>Back</Button>
      )}
    </Box>
  </Fade>
</Modal>

        </Box>
      </Box>
    </Box>
  );
};

// Modal Styling
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
};

export default MentorDetails;
