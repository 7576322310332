// src/pages/BlogDetail.js

import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchBlog, fetchMentorProfile } from '../services/api';
import { Container, Typography, CircularProgress, Box, Avatar, Button } from '@mui/material';
import Head from '../components/Head';

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [author, setAuthor] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const blogData = await fetchBlog(id);
        setBlog(blogData);
        if (blogData.author) {
          const authorData = await fetchMentorProfile(blogData.author);
          setAuthor(authorData);
        }
      } catch (error) {
        console.error('Error fetching blog or author:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <Head>
      <Container>
        <Typography variant="h3" gutterBottom>
          {blog.title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          {blog.seo_description}
        </Typography>
        <img src={blog.thumbnail || "/default-thumbnail.jpg"} alt={blog.title} style={{ width: '100%', marginBottom: '20px' }} />
        <div dangerouslySetInnerHTML={{ __html: blog.content }} />
        {author && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
            <Avatar src={author.profile_picture || "/default-avatar.png"} alt={author.username} />
            <Box sx={{ ml: 2 }}>
              <Typography variant="body1">{author.name}</Typography>
              <Typography variant="body2" color="textSecondary">{author.bio}</Typography>
              <Button component={Link} to={`/mentors/${author.id}`} variant="contained" sx={{ mt: 1 }}>
                View Profile
              </Button>
            </Box>
          </Box>
        )}
      </Container>
    </Head>
  );
};

export default BlogDetail;
