import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Container, Box, Avatar, Menu, MenuItem, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from 'axios';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false); // Check if the user is logged in
  const [userProfile, setUserProfile] = useState({}); // Store user profile info
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null); // Anchor for profile menu dropdown
  const navigate = useNavigate();

  // Fetch user profile data
  const fetchUserProfile = async () => {
    const token = localStorage.getItem('token'); // Check for auth token in localStorage
    if (token) {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/user-profile/', {
          headers: { Authorization: `Token ${token}` },
        });
        setUserProfile(response.data);
        setLoggedIn(true);
      } catch (error) {
        console.error('Error fetching user profile', error);
      }
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  // Open profile menu
  const handleProfileMenuOpen = (event) => {
    setProfileMenuAnchor(event.currentTarget);
  };

  // Close profile menu
  const handleProfileMenuClose = () => {
    setProfileMenuAnchor(null);
  };

  // Log out function
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token
    setLoggedIn(false); // Update state
    navigate('/login'); // Redirect to login
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          width: 'auto',
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: '#fb2f60', // Darker red for the AppBar
            color: '#fff', // White text color for contrast
          }}
        >
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ fontWeight: 'bold', color: '#fff' }} // Slightly lighter red for the text
            >
              Menttalk Dashboard
            </Typography>

            {/* Conditional rendering based on whether the user is logged in */}
            {loggedIn ? (
              <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleProfileMenuOpen}>
                <Avatar
                  src={userProfile.profile_picture}
                  alt={userProfile.username}
                  sx={{ marginRight: '10px' }}
                />
                <ArrowDropDownIcon sx={{ color: '#fff' }} />
              </Box>
            ) : (
              <Button
                component={Link}
                to="/login"
                sx={{ color: '#fff', fontSize: '16px', textTransform: 'none' }}
              >
                Login
              </Button>
            )}
          </Toolbar>
        </AppBar>
        
        {/* Profile Menu Dropdown */}
        <Menu
          anchorEl={profileMenuAnchor}
          open={Boolean(profileMenuAnchor)}
          onClose={handleProfileMenuClose}
        >
          <MenuItem component={Link} to="/dashboard">Dashboard</MenuItem>
          <MenuItem component={Link} to="/profile">My Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>

        <Toolbar /> {/* This adds space for the AppBar */}
        <Container>
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
