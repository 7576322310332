// src/components/Head.js
import React from 'react';
import Header from './Header';
import Navbar from './NavBar';

const Head = ({ children }) => {
  return (
    <div>
      <Header />
      <Navbar />
      <main>{children}</main>
    </div>
  );
};

export default Head;
