import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, Link, Avatar } from '@mui/material';
import axios from 'axios';

const BlogsCTA = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/about/1');
        setBlogs(response.data.blogs);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };
    fetchBlogs();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        py: 6,
        backgroundColor: '#fff', // Dark background color
        color: '#fff',
      }}
    >
      {/* Blog Cards Container */}
      <Box sx={{ maxWidth: '1200px', margin: '0 auto', px: 4 }}>
        <Grid container spacing={4}>
          {blogs.map((blog, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Link href={blog.url} target="_blank" underline="none">
                <Card
                  sx={{
                    borderRadius: '16px',
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                    },
                    background: 'linear-gradient(135deg, #fed7db 0%, #fed7tb 100%)',
                  }}
                >
                  <CardContent>
                    <Avatar
                      variant="square"
                      src={blog.image}
                      alt={blog.title}
                      sx={{ width: '100%', height: 200, borderRadius: '12px' }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, color: '#333' }}>
                      {blog.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#888', mt: 1 }}>
                      Read more →
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default BlogsCTA;
