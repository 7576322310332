import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, TextField, Typography, Grid, Divider } from '@mui/material';

const RegistrationForm = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [logo, setLogo] = useState(null);
  const [usernameError, setUsernameError] = useState(null);
  const [emailError, setEmailError] = useState(null); // For real-time email check
  const navigate = useNavigate();

  // Fetch the logo from API
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/navigation/api/menus/');
        setLogo(response.data.logos.normal_logo);
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, []);

  // Check if username exists
  useEffect(() => {
    const checkUsernameExists = async () => {
      if (username) {
        try {
          const response = await axios.get(`http://52.90.169.75:8000/api/check-username/?username=${username}`);
          if (response.data.exists) {
            setUsernameError('Username is already taken. Please choose another.');
          } else {
            setUsernameError(null);
          }
        } catch (error) {
          console.error('Error checking username:', error);
        }
      }
    };

    const delayDebounceFn = setTimeout(() => {
      checkUsernameExists();
    }, 500); // Add debounce to avoid calling API on every keystroke

    return () => clearTimeout(delayDebounceFn);
  }, [username]);

  // Check if email exists
  useEffect(() => {
    const checkEmailExists = async () => {
      if (email) {
        try {
          const response = await axios.get(`http://52.90.169.75:8000/api/check-email/?email=${email}`);
          if (response.data.exists) {
            setEmailError('Email is already registered. Please choose another.');
          } else {
            setEmailError(null);
          }
        } catch (error) {
          console.error('Error checking email:', error);
        }
      }
    };

    const delayDebounceFn = setTimeout(() => {
      checkEmailExists();
    }, 500); // Add debounce to avoid calling API on every keystroke

    return () => clearTimeout(delayDebounceFn);
  }, [email]);

  const handleRegister = async () => {
    if (usernameError || emailError) return; // Prevent registration if there are errors

    try {
      await axios.post('http://52.90.169.75:8000/api/auth/register/', {
        email,
        username,
        password,
      });
      setMessage('Registration successful. Please check your email for the OTP.');
      // Redirect to OTP verification page with username as state
      navigate('/verify-otp', { state: { username } });
    } catch (error) {
      setMessage('Registration failed.');
      console.error('Error during registration:', error);
    }
  };

  return (
    <Grid container sx={{
      minHeight: '100vh',
      background: 'linear-gradient(135deg, #c0332b 0%, #fed7db 100%)',
      backgroundImage: `
        radial-gradient(at 10% 20%, rgba(192, 51, 43, 0.3) 0%, transparent 50%),
        radial-gradient(at 80% 80%, rgba(88, 0, 23, 0.4) 0%, transparent 50%),
        radial-gradient(at 50% 50%, rgba(251, 47, 96, 0.2) 0%, transparent 40%),
        radial-gradient(at 90% 10%, rgba(254, 215, 219, 0.3) 0%, transparent 50%)
      `,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      {/* Left Section - Registration Form */}
      <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box
          sx={{
            width: '80%',
            maxWidth: '400px',
            backgroundColor: '#fff',
            padding: '40px',
            borderRadius: '12px',
            boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
            position: 'relative',
          }}
        >
          {/* Display Logo */}
          {logo && (
            <Box sx={{ textAlign: 'center', mb: 4 }}>
              <img
                src={logo}
                alt="Logo"
                style={{ height: '40px', marginBottom: '20px' }}
              />
            </Box>
          )}

          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
            Create your account
          </Typography>

          {message && <Typography variant="body1" color="error" sx={{ textAlign: 'center', mb: 2 }}>{message}</Typography>}

          <Box>
            <TextField
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              margin="normal"
              required
              error={Boolean(usernameError)}
              helperText={usernameError}
            />
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              type="email"
              required
              error={Boolean(emailError)}
              helperText={emailError}
            />
            <TextField
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
              type="password"
              required
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 2,
                backgroundColor: '#fb2f60',
                '&:hover': {
                  backgroundColor: '#c0332b',
                },
                padding: '10px',
                fontWeight: 'bold',
              }}
              onClick={handleRegister}
              disabled={Boolean(usernameError || emailError)} // Disable the button if there's an error
            >
              Register
            </Button>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <Typography variant="body2" color="textSecondary">
              Already have an account?{' '}
              <Typography
                component="span"
                sx={{ textDecoration: 'underline', cursor: 'pointer', color: '#fb2f60' }}
                onClick={() => navigate('/login')}
              >
                Log In
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* Right Section - Quote */}
      <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ padding: '40px', textAlign: 'center', color: '#580017' }}>
          <Typography variant="h6" sx={{ fontStyle: 'italic', mb: 2 }}>
            “Before Menttalk, I used to research problems myself, but always found it hard to relate learnings back to my exact challenge.”
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Vaibhav Yadav
          </Typography>
          <Typography variant="body2">
            Founder at Vedspace Ventures
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RegistrationForm;
