import React, { useEffect, useState } from 'react';
import {  Button, Typography, Box, Card, CardContent, Avatar, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { fetchMentors,  fetchMenteeBookings } from '../services/api';

const MenteeDashboard = () => {
  const [mentors, setMentors] = useState([]);

  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const mentorsData = await fetchMentors();
        setMentors(mentorsData);

        

        const bookingsData = await fetchMenteeBookings();  // Fetch the mentee bookings
        setBookings(bookingsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <Box sx={{ padding: '20px', backgroundColor: '#f7f7f7' }}>

      {/* Bookings Section */}
      <Typography variant="h6" gutterBottom>Your Bookings</Typography>
      <Grid container spacing={3}>
        {bookings.length > 0 ? (
          bookings.map((booking) => (
            <Grid item xs={12} sm={6} md={4} key={booking.id}>
              <Card sx={{ padding: 2,borderRadius:'20px' }}>
                <CardContent>
                  <Avatar src={booking.mentor_image} alt={booking.mentor} sx={{ width: 60, height: 60, marginBottom: 2 }} />
                  <Typography variant="h6">{booking.mentor_name}</Typography>
                  <Typography variant="body2">Date: {booking.booking_date}</Typography>
                  <Typography variant="body2">Time: {booking.booking_start_time} - {booking.booking_end_time}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" sx={{ marginTop: 2 }}>No Upcoming Trial Bookings</Typography>
        )}
      </Grid>

      {/* Mentor Recommendations Section */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>Mentor Recommendations</Typography>
        <Grid container spacing={3}>
          {mentors.map((mentor) => (
            <Grid item xs={12} sm={6} md={4} key={mentor.id}>
              <Card sx={{ padding: 2 ,borderRadius:'20px'}}>
                <CardContent>
                  <Avatar src={mentor.image} alt={mentor.name} sx={{ width: 60, height: 60, marginBottom: 2 }} />
                  <Typography variant="h6">{mentor.name}</Typography>
                  <Typography variant="body2">{mentor.bio}</Typography>
                  <Button
                    variant="contained"
                    sx={{ marginTop: 2 }}
                    component={Link}
                    to={`/book-mentor/${mentor.id}`}
                  >
                    Book a Free Trial Session
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

     
    </Box>
  );
};

export default MenteeDashboard;
