import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Grid, Card, CardContent, CardMedia, Avatar, Button } from '@mui/material';
import axios from 'axios';
import Slider from "react-slick";

// Import CSS files for react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeIntro = () => {
  const [homepageData, setHomepageData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/home/1/'); // Replace with the correct API endpoint
        setHomepageData(response.data);
      } catch (error) {
        console.error('Error fetching homepage data:', error);
      }
    };
    fetchData();
  }, []);

  if (!homepageData) {
    return <div>Loading...</div>;
  }

  const { ceo_details, section_title, cards, company_logos } = homepageData;

  // Settings for the company logos slider with infinite scroll
  const logoSliderSettings = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Continuous scrolling
    cssEase: 'linear',
    pauseOnHover: false, // Keep logos moving even when hovered
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box sx={{ backgroundColor: '#fff', py: 6 }}>
      {/* Sliding company logos */}
      <Container maxWidth="lg">
        <Slider {...logoSliderSettings}>
          {company_logos.map((logo) => (
            <Box key={logo.name} sx={{ display: 'flex', justifyContent: 'center', px: 2 }}>
              <img src={logo.logo} alt={logo.name} style={{ maxWidth: '120px', maxHeight: '60px', opacity: 0.8 }} />
            </Box>
          ))}
        </Slider>
      </Container>

      {/* CEO Section */}
      <Box sx={{ textAlign: 'center', my: 6, color: 'black' }}>
        <Typography variant="h6" sx={{ fontStyle: 'italic', maxWidth: '800px', mx: 'auto' }}>
          "{ceo_details.quote}"
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
          <Avatar src={ceo_details.image} alt={ceo_details.name} sx={{ width: 60, height: 60, mr: 2 }} />
          <Box>
            <Typography variant="h6" sx={{ color: '#ec2d5b' }}>{ceo_details.name}</Typography>
            <Typography variant="body2" color="black">{ceo_details.position} at {ceo_details.company}</Typography>
          </Box>
        </Box>
      </Box>

      {/* Section Title */}
      <Box sx={{ textAlign: 'center', my: 6 }}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black' }}>{section_title}</Typography>
      </Box>

      {/* Cards Section */}
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {cards.map((card) => (
            <Grid item xs={12} sm={6} md={4} key={card.title}>
              <Card
                sx={{
                  borderRadius: '16px',
                  overflow: 'hidden',
                  height: '100%',
                  backgroundColor: '#f8f9fa',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 6px 25px rgba(0,0,0,0.15)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="160"
                  image={card.image}
                  alt={card.title}
                  sx={{
                    filter: 'brightness(85%)',
                    transition: 'filter 0.3s ease',
                    '&:hover': {
                      filter: 'brightness(100%)',
                    }
                  }}
                />
                <CardContent sx={{ color: 'black', textAlign: 'center', p: 3 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {card.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2, color: '#555' }}>
                    {card.description}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#ec2d5b',
                      color: '#fff',
                      borderRadius: '8px',
                      fontWeight: 'bold',
                      px: 4,
                      py: 1,
                      boxShadow: '0px 14px 30px rgba(0, 0, 0, 0.2)',
                      '&:hover': {
                        backgroundColor: '#590119',
                        boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.3)',
                      },
                    }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HomeIntro;
