import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import axios from 'axios';

const MenttalkCTA = () => {
  const [ctaData, setCtaData] = useState(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const fetchCtaData = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/menttalkcta/1/');
        setCtaData(response.data);
      } catch (error) {
        console.error('Error fetching CTA data:', error);
      }
    };
    fetchCtaData();
  }, []);

  const handleMouseMove = (e) => {
    const card = imageRef.current;
    const rect = card.getBoundingClientRect();
    const xAxis = (rect.left + rect.width / 2 - e.clientX) / 30;
    const yAxis = (rect.top + rect.height / 2 - e.clientY) / 30;
    card.style.transform = `translate(${xAxis}px, ${yAxis}px)`;
  };

  const handleMouseLeave = () => {
    const card = imageRef.current;
    card.style.transform = `translate(0px, 0px)`; // Reset the position
  };

  if (!ctaData) {
    return <div>Loading...</div>;
  }

  const { title, subtitle, image, button_text, button_url, price, preorder_info, availability_info, extra_info } = ctaData;

  return (
    <Box
      sx={{
        background: 'linear-gradient(to bottom, #f1f5f9, #ffffff)', // Subtle light gradient background
        textAlign: 'center',
        padding: '50px 0',
      }}
    >
      {/* Title and Subtitle */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" sx={{ color: '#333', fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography 
          variant="h5" 
          sx={{ 
            color: '#555', 
            mt: 1, 
            background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%)',
            backgroundClip: 'text',
            '-webkit-background-clip': 'text',
            animation: 'shine 2s infinite',
          }}
        >
          {subtitle}
        </Typography>
      </Box>

      {/* Top Image with Mouse Movable Effect */}
      <Box
        ref={imageRef}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        sx={{
          position: 'relative',
          display: 'inline-block',
          transformStyle: 'preserve-3d',
          perspective: '1000px',
          marginTop: '40px',
        }}
      >
        <Box
          component="img"
          src={image}
          alt="Menttalk CTA"
          sx={{
            width: '90%',
            height: 'auto',
            boxShadow: '0px 20px 60px rgba(0,0,0,0.2)', // Softer shadow
            borderRadius: '20px',
            transition: 'transform 0.2s ease',
          }}
        />
      </Box>

      {/* Button */}
      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          href={button_url}
          sx={{
            backgroundColor: '#ec2d5b', // Soft green button
            color: '#fff',
            padding: '10px 20px',
            fontSize: '16px',
            borderRadius: '50px',
            boxShadow: '0px 5px 15px rgba(0, 105, 92, 0.4)',
            '&:hover': {
              backgroundColor: '#004d40', // Darker green on hover
            },
          }}
        >
          {button_text}
        </Button>
      </Box>

      {/* Bottom Information */}
      <Box sx={{ mt: 4, color: '#333' }}>
        <Typography variant="h6">{price}</Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          {preorder_info}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          {availability_info}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2, color: 'rgba(51, 51, 51, 0.8)' }}>
          {extra_info}
        </Typography>
      </Box>

      {/* Keyframe for the shine animation */}
      <style>
        {`
          @keyframes shine {
            0% {
              background-position: -200%;
            }
            100% {
              background-position: 200%;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default MenttalkCTA;
