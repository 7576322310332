import React, { useEffect, useState } from 'react';
import MentorCard from '../components/MentorCard';
import { fetchMentors } from '../services/api'; // Import the API function
import { Container, Typography, Grid, Box, Paper } from '@mui/material';
import FilterSidebar from '../components/FilterSidebar'; // Import your filter sidebar component
import './ExploreMentors.css'; // Import the CSS for styling

const ExploreMentors = () => {
  const [mentors, setMentors] = useState([]);

  useEffect(() => {
    const loadMentors = async () => {
      try {
        const mentorData = await fetchMentors();
        setMentors(mentorData);
      } catch (error) {
        console.error('Error fetching mentors:', error);
      }
    };

    loadMentors();
  }, []);

  return (
    <Container maxWidth="lg" className="explore-mentors">
      <Typography variant="h4" align="center" gutterBottom>
        Explore Mentors
      </Typography>
      <Grid container spacing={4}>
        {/* Sidebar */}
        <Grid item xs={12} md={3}>
          <Paper className="filter-sidebar">
            <FilterSidebar />
          </Paper>
        </Grid>

        {/* Mentor Cards */}
        <Grid item xs={12} md={9}>
          <Box className="mentor-grid">
            {mentors.map((mentor) => (
              <Box key={mentor.id} className="mentor-card-wrapper">
                <MentorCard mentor={mentor} />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ExploreMentors;
