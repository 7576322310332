import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, CircularProgress, Avatar, Grid, Tabs, Tab, LinearProgress, InputAdornment } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { fetchMenteeProfile, updateMenteeProfile } from '../services/api';
import Layout from './Layout';  // Assuming Layout is located correctly

const MenteeProfile = () => {
  const [menteeProfile, setMenteeProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const [tabValue, setTabValue] = useState(0); // Active tab value
  const [error, setError] = useState(null);
  const [profileCompletion, setProfileCompletion] = useState(0); // Profile completion percentage

  useEffect(() => {
    const loadProfile = async () => {
      try {
        const data = await fetchMenteeProfile();
        setMenteeProfile(data);
        setFormData(data); // Pre-fill form data with fetched profile data
        calculateCompletion(data); // Calculate initial profile completion
      } catch (error) {
        setError('Failed to load profile');
      } finally {
        setLoading(false);
      }
    };

    loadProfile();
  }, []);

  const calculateCompletion = (data) => {
    const totalFields = 16; // Total fields to consider for completion (including short_intro)
    let completedFields = 0;

    for (const field in data) {
      if (data[field] && field !== 'profile_picture') {
        completedFields++;
      }
    }
    setProfileCompletion(Math.round((completedFields / totalFields) * 100));
  };

  const handleInputChange = (e) => {
    const updatedFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(updatedFormData);
    calculateCompletion(updatedFormData);
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePictureFile(file);
    }
  };

  const handleSave = async () => {
    setEditing(false);
    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append('first_name', formData.first_name || '');
      formDataToSubmit.append('last_name', formData.last_name || '');
      formDataToSubmit.append('phone_number', formData.phone_number || '');
      formDataToSubmit.append('address', formData.address || '');
      formDataToSubmit.append('city', formData.city || '');
      formDataToSubmit.append('state', formData.state || '');
      formDataToSubmit.append('pin', formData.pin || '');
      formDataToSubmit.append('country', formData.country || '');
      formDataToSubmit.append('short_intro', formData.short_intro || ''); // Including short_intro
      formDataToSubmit.append('company_name', formData.company_name || '');
      formDataToSubmit.append('designation', formData.designation || '');
      formDataToSubmit.append('gst_number', formData.gst_number || '');
      formDataToSubmit.append('age_of_startup', formData.age_of_startup || '');
      formDataToSubmit.append('industry_of_startup', formData.industry_of_startup || '');
      
      if (profilePictureFile) {
        formDataToSubmit.append('profile_picture', profilePictureFile);
      }

      await updateMenteeProfile(formDataToSubmit);  // Use FormData object
      setMenteeProfile(formData);
    } catch (error) {
      setError('Failed to update profile');
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderTickIcon = (value) => {
    return value ? <InputAdornment position="end"><CheckCircleOutlineIcon style={{ color: 'green' }} /></InputAdornment> : null;
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Layout>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>Mentee Profile</Typography>

        <LinearProgress
  variant="determinate"
  value={profileCompletion}
  sx={{ marginBottom : '10px',
    backgroundColor: '#f0f0f0', // The background of the progress track
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#ec2d5b'// Custom color for the progress bar itself
    },
  }}
/>
        <Typography variant="body1">{profileCompletion}% Profile Completed</Typography>

        <Tabs
  value={tabValue}
  onChange={handleTabChange}
  sx={{
    marginBottom: 4,
    '& .MuiTabs-indicator': {
      backgroundColor: '#ec2d5b',  // Custom indicator color
    },
    '& .Mui-selected': {
      color: '#ec2d5b',  // Custom text color for selected tab
    },
  }}
>
  <Tab label="Personal Info" />
  <Tab label="Business Details" />
</Tabs>



        {tabValue === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Avatar
                alt={menteeProfile.username}
                src={formData.profile_picture || menteeProfile.profile_picture}
                sx={{ width: 150, height: 150 }}
              />
              {editing && (
                <input type="file" accept="image/*" onChange={handleProfilePictureChange} />
              )}
            </Grid>

            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    name="first_name"
                    value={formData.first_name || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.first_name) }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    name="last_name"
                    value={formData.last_name || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.last_name) }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Username"
                    name="username"
                    value={formData.username || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    name="email"
                    value={formData.email || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Phone Number"
                    name="phone_number"
                    value={formData.phone_number || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.phone_number) }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address"
                    name="address"
                    value={formData.address || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.address) }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="City"
                    name="city"
                    value={formData.city || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.city) }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="State"
                    name="state"
                    value={formData.state || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.state) }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Pin Code"
                    name="pin"
                    value={formData.pin || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.pin) }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Country"
                    name="country"
                    value={formData.country || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.country) }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Short Intro"
                    name="short_intro"
                    value={formData.short_intro || ''}
                    multiline
                    rows={4}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.short_intro) }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {tabValue === 1 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Company Name"
                    name="company_name"
                    value={formData.company_name || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.company_name) }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Designation"
                    name="designation"
                    value={formData.designation || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.designation) }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="GST Number"
                    name="gst_number"
                    value={formData.gst_number || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.gst_number) }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Age of Startup"
                    name="age_of_startup"
                    type="number"
                    value={formData.age_of_startup || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.age_of_startup) }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Industry of Startup"
                    name="industry_of_startup"
                    value={formData.industry_of_startup || ''}
                    onChange={handleInputChange}
                    fullWidth
                    disabled={!editing}
                    InputProps={{ endAdornment: renderTickIcon(formData.industry_of_startup) }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {editing ? (
          <Button
            variant="contained"
            sx={{ backgroundColor: '#ec2d5b', '&:hover': { backgroundColor: '#d1274f' } }}
            onClick={handleSave}
          >
            Save Changes
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{ backgroundColor: '#ec2d5b', '&:hover': { backgroundColor: '#d1274f' } }}
            onClick={() => setEditing(true)}
          >
            Edit Profile
          </Button>
        )}
      </Box>
    </Layout>
  );
};

export default MenteeProfile;
