import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, Avatar } from '@mui/material';
import axios from 'axios';

const BoardOfAdvisors = () => {
  const [advisors, setAdvisors] = useState([]);

  useEffect(() => {
    const fetchAdvisors = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/about/1'); // Replace with correct API URL
        setAdvisors(response.data.board_of_advisors);
      } catch (error) {
        console.error('Error fetching advisors:', error);
      }
    };
    fetchAdvisors();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        py: 8,
        background: 'linear-gradient(180deg, #580017 0%, #c0332b 100%)', // Gradient background // Dark background color for the section
        color: '#fff',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {/* Section Content Wrapper */}
      <Box sx={{ maxWidth: '1200px', width: '100%', px: 4 }}>
        {/* Section Title */}
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 6 }}>
          Board of Advisors
        </Typography>

        {/* Grid to display advisors */}
        <Grid container spacing={3} justifyContent="center">
          {advisors.map((advisor, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              {/* Card for each advisor */}
              <Card
                sx={{
                  backgroundColor: '#fff',
                  color: '#000',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {/* Advisor Image */}
                <Avatar
                  src={advisor.photo}
                  alt={advisor.name}
                  sx={{
                    width: '100%',
                    height: '250px',
                    objectFit: 'cover',
                    borderRadius: '0px',
                  }}
                />

                {/* Advisor Details */}
                <CardContent
                  sx={{
                    padding: '16px',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {advisor.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#666',
                      mt: 1,
                      mb: 2,
                      height: '60px', // Limit height for bio
                      overflow: 'hidden', // Hide overflow
                      textOverflow: 'ellipsis',
                      whiteSpace: 'normal',
                    }}
                  >
                    {advisor.bio}
                  </Typography>

                  
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default BoardOfAdvisors;
