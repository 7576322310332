import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button, Card, CardMedia } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs'; // For handling date and time

const EventsComing = () => {
  const [eventData, setEventData] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/events/');
        setEventData(response.data[0]); // Use the first event in the array for now
      } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };

    fetchEventData();
  }, []);

  useEffect(() => {
    if (eventData) {
      const eventDate = dayjs(`${eventData.date} ${eventData.start_time}`);
      const calculateTimeLeft = () => {
        const now = dayjs();
        const diff = eventDate.diff(now, 'second'); // Calculate difference in seconds
        const days = Math.floor(diff / (3600 * 24));
        const hours = Math.floor((diff % (3600 * 24)) / 3600);
        const minutes = Math.floor((diff % 3600) / 60);
        const seconds = diff % 60;
        setTimeLeft({ days, hours, minutes, seconds });
      };

      calculateTimeLeft();
      const interval = setInterval(calculateTimeLeft, 1000); // Update every second
      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [eventData]);

  if (!eventData || !timeLeft) {
    return <Typography>Loading event...</Typography>;
  }

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        backgroundImage: `url(${eventData.background_image})`, // Background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        textAlign: 'left',
        py: 25,
      }}
    >
      {/* Shadow Overlay on Background */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.6)', // Dark overlay on image
          zIndex: 1,
        }}
      />

      {/* Event Content with Text, Image and Details */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2, // Above the overlay
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 20px',
        }}
      >
        <Grid container spacing={4} justifyContent="space-between" alignItems="center">
          {/* Event Title and Description (Center) */}
          

          {/* Event Image (Right Side) */}
          <Grid item xs={12} md={3}>
            <Card
              sx={{
                opacity: 0.9,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
              }}
            >
              <CardMedia
                component="img"
                image={eventData.photo}
                alt="Event Image"
                sx={{
                  height: '250px',
                  width: '750px',
                  objectFit: 'cover',
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              {eventData.title}
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {eventData.description}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
              by {eventData.mentor ? eventData.mentor : 'Unknown Mentor'}
            </Typography>
          </Grid>

          {/* Event Details (Far Right) */}
          <Grid item xs={12} md={3}>
            <Card
              sx={{
                padding: 4,
                borderRadius: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
                color: '#fff',
              }}
            >
              <Typography variant="h6">
                <strong>{dayjs(eventData.date).format('MMM DD')}</strong> {dayjs(eventData.date).format('dddd')}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {dayjs(eventData.start_time).format('h:mm A')} - {dayjs(eventData.end_time).format('h:mm A')}
              </Typography>

              {/* Countdown Timer */}
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="h4" component="div">
                    {timeLeft.days}
                  </Typography>
                  <Typography variant="body2">Days</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h4" component="div">
                    {timeLeft.hours}
                  </Typography>
                  <Typography variant="body2">Hours</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h4" component="div">
                    {timeLeft.minutes}
                  </Typography>
                  <Typography variant="body2">Minutes</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h4" component="div">
                    {timeLeft.seconds}
                  </Typography>
                  <Typography variant="body2">Seconds</Typography>
                </Grid>
              </Grid>

              <Typography variant="body2" sx={{ mt: 3 }}>
                <strong>Location:</strong> {eventData.location}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>From:</strong> ₹{eventData.price}
              </Typography>

              <Button
                variant="contained"
                href={`/events/${eventData.id}`}
                sx={{
                  mt: 4,
                  backgroundColor: '#1976D2',
                  '&:hover': {
                    backgroundColor: '#1565C0',
                  },
                }}
              >
                Tickets
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EventsComing;
