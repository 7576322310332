import React from 'react';
import { Box, Typography, Checkbox, FormControlLabel, TextField, Button } from '@mui/material';

const FilterSidebar = () => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Filter By
      </Typography>
      <TextField
        label="Domain"
        placeholder="eg: frontend, backend, etc."
        variant="outlined"
        fullWidth
        margin="normal"
      />
      <Box mt={2}>
        <Typography variant="subtitle1">Offering Mentorship For</Typography>
        <FormControlLabel control={<Checkbox />} label="Freshers" />
        <FormControlLabel control={<Checkbox />} label="Working Professionals" />
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1">Companies</Typography>
        <TextField
          label="Companies"
          placeholder="eg: amazon, google, microsoft etc.."
          variant="outlined"
          fullWidth
          margin="normal"
        />
      </Box>
      <Box mt={2}>
        <Button variant="contained" color="primary" fullWidth>
          Apply Filters
        </Button>
      </Box>
    </Box>
  );
};

export default FilterSidebar;
