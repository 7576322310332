import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';

const AboutIntro = () => {
  const [introData, setIntroData] = useState(null);

  useEffect(() => {
    const fetchIntroData = async () => {
      try {
        const response = await axios.get('http://52.90.169.75:8000/api/webadmin/about/1'); // Replace with correct API URL
        setIntroData(response.data);
      } catch (error) {
        console.error('Error fetching intro data:', error);
      }
    };
    fetchIntroData();
  }, []);

  if (!introData) {
    return <div>Loading...</div>;
  }

  const { title, description, intro_image } = introData;

  return (
    <Box
      sx={{
        width: '100%',
        py: 12,
        background: 'linear-gradient(180deg, #580017 0%, #c0332b 100%)', // Dark blue to purple gradient background
        color: '#fff',
        textAlign: 'center',
        position: 'relative',
        zIndex: 1,
        overflow: 'hidden',
      }}
    >
      {/* Top Full-Width Gradient Circle */}
      

      {/* Text Section */}
      <Box sx={{ maxWidth: '800px', margin: '0 auto', px: 4, zIndex: 2, position: 'relative' }}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
          {title}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: '300', mb: 4 }}>
          {description}
        </Typography>
      </Box>

      {/* Image Section */}
      {intro_image ? (
        <Box
          sx={{
            maxWidth: '1000px',
            margin: '0 auto',
            borderRadius: '30px', // Rounded bottom corners
            overflow: 'hidden',
            boxShadow: '20px', // Soft shadow around the image
            zIndex: 2,
            position: 'relative',
            mt: 4, // Margin on top of the image to create space between the text and the image
          }}
        >
          <img
            src={intro_image}
            alt="About Us Intro"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover',
            }}
          />
        </Box>
      ) : (
        <Typography variant="body1" color="textSecondary">
          No image available
        </Typography>
      )}

      {/* Bottom Full-Width Semi-Circle Gradient */}
      <Box
        sx={{
          position: 'absolute',
          bottom: '-200px', // Push the bottom gradient down to create a semicircle effect
          left: 0,
          width: '100%',
          height: '1200px',
          background: 'radial-gradient(circle at bottom, rgba(255, 255, 255, 0.15), rgba(0, 0, 0, 0))', // Semi-circle gradient
          zIndex: 0,
        }}
      />
    </Box>
  );
};

export default AboutIntro;
