// src/pages/PrivacyPolicyPage.js
import React, { useEffect, useState } from 'react';
import { fetchPrivacyPolicyPage } from '../services/api';
import Header from '../components/Header';
import Navbar from '../components/NavBar';

const PrivacyPolicyPage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const privacyPolicyData = await fetchPrivacyPolicyPage();
      setData(privacyPolicyData);
    };

    getData();
  }, []);

  if (!data) return <div>Loading...</div>;

  return (
    <div>
      <Header />
      <Navbar />
      <h1>{data.title}</h1>
      <p>{data.content}</p>
    </div>
  );
};

export default PrivacyPolicyPage;
