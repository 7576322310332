// src/pages/MentorProfiling.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Avatar, Grid, Button } from '@mui/material';
import axios from 'axios';

const MentorProfiling = () => {
  const { id } = useParams();  // Get the mentor ID from the URL
  const [mentor, setMentor] = useState(null);

  useEffect(() => {
    const fetchMentorDetails = async () => {
      try {
        const response = await axios.get(`http://52.90.169.75:8000/api/mentors/${id}/`);
        setMentor(response.data);
      } catch (error) {
        console.error('Error fetching mentor details:', error);
      }
    };

    fetchMentorDetails();
  }, [id]);

  if (!mentor) {
    return <Typography>Loading mentor details...</Typography>;
  }

  return (
    <Box sx={{ padding: '40px 0', backgroundColor: '#f8f9fa' }}>
      <Box sx={{ maxWidth: '1000px', margin: '0 auto', textAlign: 'center' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Avatar 
              alt={mentor.name} 
              src={mentor.profile_picture} 
              sx={{ width: 150, height: 150, margin: '0 auto' }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" fontWeight="bold">{mentor.name}</Typography>
            <Typography variant="subtitle1" color="textSecondary">{mentor.description}</Typography>
            <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1 }}>
              {mentor.bio}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mt: 2 }}>
              <Typography>⭐ {mentor.reviews.length > 0 ? (mentor.reviews.reduce((acc, review) => acc + review.rating, 0) / mentor.reviews.length).toFixed(2) : "No reviews yet"}</Typography>
              <Typography variant="body2"> {mentor.reviews.length} reviews</Typography>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: '30px', textAlign: 'left' }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Pricing & Availability
          </Typography>
          <Grid container spacing={2}>
            {mentor.mentor_time_blocks.map((block) => (
              <Grid item xs={6} sm={3} key={block.time_block.id}>
                <Box sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px', textAlign: 'center' }}>
                  <Typography variant="subtitle1">{block.time_block.duration} min</Typography>
                  <Typography variant="body2">{block.price} INR</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ marginTop: 3 }}>
          <Button variant="contained" color="primary">Request a Call</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MentorProfiling;
